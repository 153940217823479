<template>
  <div class="video" v-if="statistic">
    <div class="video-filling">
      <VideoElement1 :step="step"></VideoElement1>
      <VideoElement2 :step="step"></VideoElement2>
      <VideoElement3 :step="step"></VideoElement3>
      <VideoElement4 :step="step" :statistic="statistic"></VideoElement4>
      <VideoElement5 :step="step" :statistic="statistic"></VideoElement5>
      <VideoElement6 :step="step" :superpowers="superpowers?.slice(0, 3)"></VideoElement6>
      <VideoElement7 :step="step" :superpowers="superpowers?.slice(3, 5)"></VideoElement7>
      <VideoElement8 :step="step"></VideoElement8>
      <VideoElement9 :step="step" :competitions="superpowers?.[0]?.competition"></VideoElement9>
      <VideoElement10 :step="step"></VideoElement10>
      <VideoElement11 :step="step" :roles="roles"></VideoElement11>
      <VideoElement12 :step="step"></VideoElement12>
      <VideoElement13 :step="step" :motivations="motivations?.[0]"></VideoElement13>
      <VideoElement14 :step="step"></VideoElement14>
      <VideoElement15 :step="step" :companies="companies"></VideoElement15>
      <VideoElement16 :step="step" :professions="professions"></VideoElement16>
      <VideoElement17 :step="step"></VideoElement17>
      <VideoElement18 :step="step" v-if="step === 17"></VideoElement18>
    </div>
  </div>
</template>

<script>
import VideoElement1 from "@/components/video/VideoElement1.vue";
import VideoElement2 from "@/components/video/VideoElement2.vue";
import VideoElement3 from "@/components/video/VideoElement3.vue";
import VideoElement4 from "@/components/video/VideoElement4.vue";
import VideoElement5 from "@/components/video/VideoElement5.vue";
import VideoElement6 from "@/components/video/VideoElement6.vue";
import VideoElement7 from "@/components/video/VideoElement7.vue";
import VideoElement8 from "@/components/video/VideoElement8.vue";
import VideoElement9 from "@/components/video/VideoElement9.vue";
import VideoElement10 from "@/components/video/VideoElement10.vue";
import VideoElement11 from "@/components/video/VideoElement11.vue";
import VideoElement12 from "@/components/video/VideoElement12.vue";
import VideoElement13 from "@/components/video/VideoElement13.vue";
import VideoElement14 from "@/components/video/VideoElement14.vue";
import VideoElement15 from "@/components/video/VideoElement15.vue";
import VideoElement16 from "@/components/video/VideoElement16.vue";
import VideoElement17 from "@/components/video/VideoElement17.vue";
import VideoElement18 from "@/components/video/VideoElement18.vue";
import { computed, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";

export default {
  props:['userId'],
  name: "VideoPage",
  components: {
    VideoElement1,
    VideoElement2,
    VideoElement3,
    VideoElement4,
    VideoElement5,
    VideoElement6,
    VideoElement7,
    VideoElement8,
    VideoElement9,
    VideoElement10,
    VideoElement11,
    VideoElement12,
    VideoElement13,
    VideoElement14,
    VideoElement15,
    VideoElement16,
    VideoElement17,
    VideoElement18,
  },
  setup(props) {

    const store = useStore();

    store.dispatch("fetchStudentStatisticForVideo",props.userId).then(() => {
      const timerId = setInterval(function () {
        if (step.value === 17) {
          clearInterval(timerId);
          return;
        }
        step.value++;
      }, 5000);
    });


    const statistic = computed(() => store.getters.studentStatistic);
    const step = ref(0);



    const superpowers = computed(() => {
      return statistic.value?.superpower
        ? Object.entries(statistic.value.superpower)
          .sort((a, b) => b[1] - a[1])
          .map((item) => {
            switch (item[0]) {
              case "people":
                return {
                  power: "В работе с людьми",
                  competition: [
                    "Клиентоориентированность",
                    "Управление эмоциями",
                    "Работа в команде",
                  ],
                };
              case "activity":
                return {
                  power: "В прикладной деятельности",
                  competition: [
                    "Выработка и принятие решений",
                    "Тайм-менеджмент",
                    "Настойчивость",
                  ],
                };
              case "creative":
                return {
                  power: "В творческой деятельности",
                  competition: [
                    "Креативное мышление",
                    " Саморазвитие",
                    " Умение слушать",
                  ],
                };
              case "extreme":
                return {
                  power: "В экстремальной деятельности",
                  competition: [
                    "Адаптация к изменениям",
                    "Стрессоустойчивость",
                    "Инициативность",
                  ],
                };
              case "information":
                return {
                  power: "В работе с информацией",
                  competition: [
                    "Поиск и анализ информации",
                    "Логическое мышление",
                    "Убеждение и аргументация",
                  ],
                };
              case "science":
                return {
                  power: "В исследовательской деятельности",
                  competition: [
                    "Критическое мышление",
                    "Планирование и целеполагание",
                    "Системный подход",
                  ],
                };
            }
          })
        : [
          {
            power: "В работе с людьми",
            competition: [
              "Клиентоориентированность",
              "Управление эмоциями",
              "Работа в команде",
            ],
          },
          {
            power: "В прикладной деятельности",
            competition: [
              "Выработка и принятие решений",
              "Тайм-менеджмент",
              "Настойчивость",
            ],
          },
          {
            power: "В творческой деятельности",
            competition: [
              "Креативное мышление",
              " Саморазвитие",
              " Умение слушать",
            ],
          },
          {
            power: "В экстремальной деятельности",
            competition: [
              "Адаптация к изменениям",
              "Стрессоустойчивость",
              "Инициативность",
            ],
          },
          {
            power: "В работе с информацией",
            competition: [
              "Поиск и анализ информации",
              "Логическое мышление",
              "Убеждение и аргументация",
            ],
          },
          {
            power: "В исследовательской деятельности",
            competition: [
              "Критическое мышление",
              "Планирование и целеполагание",
              "Системный подход",
            ],
          },
        ];
    });

    const roles = computed(() =>
      statistic.value?.teamRoles
        ? Object.entries(statistic.value.teamRoles)
          .filter((item) => item[1] >= 30)
          .map((item) => {
            switch (item[0]) {
              case "leader":
                return "Лидер";
              case "specialist":
                return "Специалист";
              case "ideas":
                return "Генератор идей";
              case "soul":
                return "Душа компании";
              case "expert":
                return "Эксперт";
            }
          })
        : ["Лидер", "Специалист", "Генератор идей", "Душа компании", "Эксперт"]
    );

    const motivations = computed(() => {
      return statistic.value?.motivation
        ? Object.entries(statistic.value.motivation)
          .sort((a, b) => b[1] - a[1])
          .map((item) => {
            switch (item[0]) {
              case "material":
                return [
                  "Прежде всего для тебя важна материальная выгода в любой деятельности.",
                  " Ты работаешь, представляя конечное вознаграждение за приложенные усилия. При выборе компании или должности в будущем, скорее всего, будешь акцентироваться на финансовом благополучии. И это супер! Деньги — ресурс, который может сделать жизнь комфортнее",
                ];
              case "team":
                return [
                  "Тебе не сильно важно, где ты будешь работать, и сколько за это будут платить, но о-о-очень важно, кто тебя окружает, и с кем надо делать движ.",
                  " Ты любишь общаться и за крутым коллективом готов пойти куда угодно. Приоритет — причастность к жизни организации. И это здорово! Таких сотрудников очень ценят.",
                ];
              case "process":
                return [
                  "Настоящий человек вдохновения! Ты получаешь удовольствие от работы.",
                  " Для тебя важны именно задачи и непосредственные функции. Занимаясь любимым делом, время может пролетать незаметно, а процесс настолько тебя увлекает, что забываются все проблемы. Приоритет — сама деятельность, а не полученный результат.",
                ];
              case "achieve":
                return [
                  "Результат — твое все! Тут отлично подойдет выражение «вижу цель – не вижу препятствий».",
                  " Неважно, какими средствами и как будет достигнуто задуманное, главное — дойти до конца. И будет еще лучше, если за это получить дипломы / кубки / награды / признания. Все то, что может подтвердить достижение. Нет, ну а что? Отличная работа всегда должна вознаграждаться! Зачастую ты соперничаешь сам с собой. Что тоже хорошо: нужно всегда сравнивать себя только с собой прежним, не оглядываясь на других.",
                ];
              case "idea":
                return [
                  "Ты черпаешь вдохновение в своих идеях!",
                  " А еще в твоем сердце точно горит яркий огонек, способный своим светом заряжать окружающих. Тебе неважно, как будет реализована твоя идея, и что ты за это получишь. Зато очень важно повлиять на мир, преследуя высшую цель или миссию.",
                ];
            }
          })
        : [
          "Прежде всего для тебя важна материальная выгода в любой деятельности",
          " Ты работаешь, представляя конечное вознаграждение за приложенные усилия. При выборе компании или должности в будущем, скорее всего, будешь акцентироваться на финансовом благополучии. И это супер! Деньги — ресурс, который может сделать жизнь комфортнее",
        ];
    });

    const companies = computed(() => statistic.value?.companies);
    const professions = computed(() => statistic.value?.professions);
    onBeforeMount(() => {
      if (document.getElementById("video-css")) return;
      const el = document.createElement("link");
      el.href = "../style/video.css";
      el.rel = "stylesheet";
      el.id = "video-css";
      document.body.append(el);
    });

    onBeforeUnmount(() => document.getElementById("video-css")?.remove());

    return {
      statistic,
      step,
      superpowers,
      roles,
      motivations,
      companies,
      professions,
    };
  },
};
</script>

<style scoped>
body {
  overflow: hidden !important;
}

.video {
  display: flex;
  max-width: 100%;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  background: #000;
}

.video-filling {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.step {
  opacity: 1;
}

@media (orientation: portrait) and (max-width: 1000px) {
  .video {
    padding: 32vh 0;
    padding: 32dvh 0;
  }

}
</style>
