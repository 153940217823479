<template>
  <div class="filling" :class="{ 'step': step === 2 }">
    <img src="@/assets/imgs/video/bg-3.webp" alt="" class="bg" />
    <div class="content">
      <p class="title">способности</p>
      <div class="about" :class="{ 'animate-2': step === 2 }">
        <p class="text text-left ">Твои способности на {{ superPower }}% выше</p>
        <div class="pie">
          <img src="@/assets/imgs/video/percentage/50-75.webp" alt="" class="pie-bg">
          <p class="skilles__ring-text">{{ superPower }}%</p>
        </div>

        <p class="text ">чем у обычного взрослого человека!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoElement3",
  props: ["step"],
  setup() {
    return {
      superPower: Math.floor(Math.random() * 7) + 76,
    };
  },
};
</script>

<style scoped>
.filling {
  display: flex;

  height: 100vh;
  overflow: hidden;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.about {
  margin-top: 9vw;
  display: flex;
  align-items: center;
}

.pie {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 20vw;
  position: relative;
}

.pie-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.animate-2 {
  animation: content-open 2s 0.5s both;
}

@keyframes content-open {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.skilles__ring-text {
  font-size: 4.69vw;
  line-height: 1;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}

.title {
  margin-top: 5.21vw;
  max-width: 50.31vw;
  text-align: center;
  font-size: 3.33vw;
  font-weight: 700;
  line-height: 143.5%;
  text-transform: uppercase;
}

.text {
  width: 27.14vw;
  font-size: 2.5vw;
  line-height: 143.5%;
  margin-left: 7.21vw;
}

.text-left {
  margin-left: 0;
  text-align: right;
  margin-right: 7.21vw;
}

.step {
  z-index: 3;
}
</style>
