<template>
  <div class="registration">
    <page-header></page-header>
    <div class="content container">

      <img src="@/assets/imgs/robot-face.png" alt="" class="chat__logo" v-if="step >= 1 && step <= 7"/>
      <div class="chat">
        <div class="chat-el" v-for="(item, index) in messages" :key="index">
          <robot-message @sendMessage="sendMessage" @setOrganization="setOrganization" :id="`msg${index}`"
                         @clearMessages="clearMessages" :loader="loader" @nextStep="step++" :message="item" :step="step"
                         v-if="item.from === 'bot'"></robot-message>
          <user-message :message="item" v-if="item.from === 'user' && item.body"></user-message>
        </div>
      </div>
      <chat-form @emailError="emailError" v-if="![3, 4, 5, 6, 12, 13, 14, 15, 16].includes(step)" v-show="!loader"
                 @setName="setName" @existEmail="existEmail" @clearMessages="clearMessages"
                 @changeLoader="loader = !loader"
                 @nextStep="step++" @sendMessage="sendMessage" :step="step"></chat-form>

    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/general/PageHeader.vue";
import ChatForm from "@/components/chat/ChatForm.vue";
import RobotMessage from "@/components/chat/RobotMessage.vue";
import UserMessage from "@/components/chat/UserMessage.vue";
import {computed, onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import {useStore} from "vuex";
import {appConfig} from "@/lib/appConfig";
import {useRouter} from "vue-router";

export default {
  name: "StudentRegistrationPage",
  components: {
    ChatForm,
    RobotMessage,
    UserMessage,
    PageHeader,
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    const registrationData = computed(() => store.getters.registrationData);
    const userData = computed(() => store.getters.userData);
    const step = ref(0);
    const openVideo = ref(false);
    const registrationError = ref('');
    const loader = ref(false);

    const clearMessages = () => {
      messages.value.length = 0;
    }


    const robotMessages = ref([
      {
        body: "Привет! 😉Меня зовут Цип.  А как твоё имя?",
        from: "bot",
        type: "text",
      },
      {
        body: "Добавь свою фамилию.",
        from: "bot",
        type: "text",
      },
      {
        body: "Осталось узнать твоё отчество. Напиши его📝",
        from: "bot",
        type: "text",
      },
      {
        body: null,
        // purpleText: "<div class=\"illustration\">\n" +
        //     "      <iframe class=\"illustration__filling\" src=\"https://rutube.ru/play/embed/7da4da60790e971fc48ae48e613cdc45\"\n" +
        //     "              frameBorder=\"0\" allow=\"clipboard-write; autoplay\" webkitAllowFullScreen mozallowfullscreen\n" +
        //     "              allowFullScreen></iframe>\n" +
        //     "    </div>",
        from: "bot",
        type: "video",
        link: true
      },
      {
        body: "Пока мы болтаем, я в Москве — на сервере💻, а ты откуда со мной общаешься🤔? ",
        from: "bot",
        type: "city",
      },

      {
        body: "А теперь расскажи, где ты сейчас учишься🤓?",
        purpleText: null,
        from: "bot",
        type: "organization",
        values: [
          "Общеобразовательная организация (школа)",
          "Профессиональная образовательная организация (ссуз)",
          "Образовательная организация высшего образования (вуз)",
        ],
      },
      {
        body: null,
        from: "bot",
        type: "course",
        values: [],
      },
      {
        body: "А как называется место, где ты сейчас получаешь свои знания🤓? ",
        purpleText: null,
        from: "bot",
        type: "text",
      },
      {
        body: "А скажи, когда ты родился📝?",
        from: "bot",
        type: "text",
      },

      {
        body: appConfig.appState.free ? "Поделись своим номером телефона, чтобы мы всегда были на связи📲" : "Мы мило общаемся и скоро продолжим 🤝, а пока поделись своим номером телефона, чтобы мы всегда были на связи 📲",
        from: "bot",
        type: "text",
      },
      {
        body: "А теперь нам нужно придумать пароль для твоего личного кабинета📝",
        from: "bot",
        type: "text",
      },
      {
        body: "Дай мне свою электронку, чтобы я мог присылать тебе интересную информацию😉, когда ты будешь не в сети.",
        from: "bot",
        type: "text",
        context: 'email'
      },
      // {
      //   body: "Мы не обсудили с тобой самое главное! Для того, чтобы получить полноценный доступ ко всем моим возможностям, тебе необходимо оплатить полный доступ.",
      //   from: "bot",
      //   type: "text",
      // },
      // {
      //   body: "А теперь давай посмотрим, что же ты получишь в результате. Вот оно! Это одно из моих главных сокровищ.",
      //   from: "bot",
      //   type: "text",
      // },
      // {
      //   body: "Я буду тебе помогать и всячески способствовать, чтобы ты его получил! Скорее смотри!",
      //   from: "bot",
      //   type: "text",
      // },
      // {
      //   body: null,
      //   from: "bot",
      //   type: 'video',
      // },
      {
        body: 'Мы мило общаемся и скоро продолжим🤝, но я должен соблюсти одну формальность: поставь галочки✅✅, чтобы  мы могли продолжить наше общение, и чтобы в будущем я смог присылать тебе полезную информацию для выбора профессии.',
        from: "bot",
        type: "policy",
      },
    ]);

    const messages = ref([robotMessages.value[step.value]]);

    const emailError = (cancelError) => {
      if (cancelError) {
        registrationError.value = '';
        return;
      }
      messages.value.push({
        body: "Такой email уже занят, введи другой email!",
        from: "bot",
        type: "text",
      });
      registrationError.value = `registrationError`;
    }

    watch(() => messages.value.length, (val) => {
      setTimeout(() => {
        document
            .getElementById(`msg${val - 1}`)
            ?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
      }, 100);
    })

    const nextStep = () => {
      clearMessages();
      step.value++;
    }

    watch(() => step.value,
        (val) => {
          if (typeof val === "string") return;

          if (userData.value) {
            if ([10, 11, 12]?.includes(val)) {
              step.value++;
              return;
            }
          }

          if (val > robotMessages.value.length - 1) {
            document.removeEventListener('click', nextStep)
            store.dispatch("submitRegistration");
            return;
          }
          messages.value.push(robotMessages.value[val]);
        });

    onBeforeMount(async () => {

      await store.dispatch("fetchRegions");
      store.commit("initRegistrationData", "student");


      if (!appConfig.appState.free) {

        if (!userData.value) {
          sessionStorage.setItem('openPaymentModal', 'true');
          return router.push({name: 'landing'});
        }

      }
    })

    onBeforeUnmount(() => store.commit("clearRegistrationData"));


    return {
      step,
      messages,
      robotMessages,
      openVideo,
      emailError,
      loader,
      clearMessages,
      existEmail() {
        const index = robotMessages.value?.findIndex(item => item?.context === 'email');

        if (index > -1) {
          robotMessages.value[index].body = "Кажется, с этого адреса уже кто-то заходил🤔. Если это твой адрес, то напиши в <a class='purple-text' target='_blank' href='mailto:tsip.rf@mail.ru'>Техподдержку</a>,либо дай мне другую электронку."
        }
      },

      setName: () => {
        robotMessages.value[
        step.value + 1].body = `Здорово, что ты здесь ${registrationData.value.firstName} 🤗! Давай я расскажу о себе, посмотри мою визитку и узнай обо мне поподробней👀`;
      },

      sendMessage(body) {
        messages.value.push({type: "text", from: "user", body});

        setTimeout(() => {
          if (registrationError.value) return;
          typeof step.value === "number"
              ? step.value++
              : (step.value = robotMessages.value.length);
        }, 150);
      },


      setOrganization: (isSchool) => {
        robotMessages.value[step.value + 1].body = isSchool
            ? "Принято! А в каком ты классе🤔?"
            : "Принято! А на каком ты курсе🤔?";

        // robotMessages.value[step.value + 1].purpleText = isSchool
        //     ? "А в каком классе ты учишься?"
        //     : "Мне интересно, на каком ты курсе?";


        robotMessages.value[step.value + 1].values = isSchool
            ? ["8 класс", "9 класс", "10 класс", "11 класс"]
            : ["1 курс", "2 курс", "3 курс", "4 курс", '5 курс'];
      },
    };
  },
};
</script>

<style scoped>
.chat__logo {
  margin: 2.188rem 0 1.688rem 0;
  width: 4.063rem;
}

.chat {
  padding-bottom: 3.5rem;
  max-width: 100%;
  width: 100%;
}

.chat-el {
  max-width: 100%;
  width: 100%;
  animation: 0.5s show ease;
}


@keyframes show {
  from {
    opacity: 0;

  }

  to {
    opacity: 1;

  }
}

/* video */
.video {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.video-filling {
  margin: auto;
  max-width: 70vw;
  width: 100%;
  position: relative;
  display: flex;
}

iframe {
  max-width: 100%;
  width: 100%;
  height: 80vh;
  height: 80dvh;
}

.close {
  position: absolute;
  right: -3rem;
  top: 0;
  padding: 0 0.62rem 0.62rem 0.62rem;
  cursor: pointer;
}

.close__img {
  width: 1.75rem;
  height: 1.75rem;
}

@media (max-width: 700px) {
  .container {
    padding: 1rem 1rem 0rem 1rem;
  }

  .chat {
    padding-bottom: 3rem;
  }

  .chat__logo {
    margin: 0 0 0.5rem 0;
    width: 5.75rem;
    object-fit: contain;
  }

  /* video */
  .video-filling {
    max-width: 100%;
  }

  .close {
    right: -0.62rem;
    top: -2.6rem;
  }

  iframe {
    height: 30vh;
    height: 30dvh;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
