<template>
  <table class="table">
    <tr class="table-caption">
      <td class="table-caption__element">
        <div class="table-caption-filling">
          Регион
          <button class="find__button" @click="viewFilter = !viewFilter">
            <img src="@/assets/imgs/search.svg" alt="" class="find__button-icon">
            Найти</button>

        </div>
        <label class="find__wrapper" v-if="viewFilter">
          <input class="find-input" type="text" placeholder="Название региона" />
          <div class="find-buttons">
            <button class="find-ok">Найти</button>
            <button class="find-reset">Сбросить</button>
          </div>
        </label>
      </td>
      <td class="table-caption__element">Дата начала</td>
      <td class="table-caption__element">Дата окночания</td>
    </tr>
    <RegionTableElement>
    </RegionTableElement>
  </table>
</template>

<script>
import RegionTableElement from './RegionTableElement.vue'

export default {
  name: "RegionTable",
  components: { RegionTableElement },
  data() {
    return {
      viewFilter: false,
    }
  }
}
</script>

<style scoped>
.table {
  max-width: 100%;
  width: 100%;
  background: rgba(30, 30, 30, 1);
}


td {
  padding: 1rem;
}

tr {
  border-bottom: 1px solid hsla(0, 0%, 100%, .08);
}

.table-caption__element {
  position: relative;

}

.table-caption-filling {
  align-items: center;
  display: flex;
  max-width: 100%;
  width: 100%;
  position: relative;
}

.find__button {
  margin-left: 1rem;
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.find__button-icon {
  width: 1.2rem;

}

.find__wrapper {
  position: absolute;
  background: #1E1E1E;
  width: 21rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.50);
  z-index: 100;
  margin-top: 1rem;
  left: 0;

}

.find-input {
  padding: 0.5rem;
  border: 1px solid #fff;
  color: #fff;
  background: none;
  border-radius: 0.2rem;
}

.find-buttons {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 0.5rem;
  gap: 0.5rem;
}

.find-ok {
  max-width: 100%;
  width: 100%;
  border: 1px solid #fff;
  padding: 5px 0;
  background: rgba(255, 255, 255, 0.30000001192092896);
  cursor: pointer;
  transition: opacity 1s ease;
  text-align: center;
  border-radius: 0.2rem;
}

.find-ok:hover {
  opacity: 0.5;
}

.find-reset {
  max-width: 100%;
  width: 100%;
  border: 1px solid #fff;
  padding: 5px 0;
  cursor: pointer;
  transition: opacity 1s ease;
  text-align: center;
  border-radius: 0.2rem;
}

.find-reset:hover {
  opacity: 0.5;
}


@media (max-width: 800px) {


  tr {
    font-size: 1rem;
  }

  .table {
    max-width: unset;
    width: 700px;
  }
}
</style>