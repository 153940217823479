<template>
  <div class="skilles-element">
    <div class="skilles-element-caption">
      <div
        class="skilles__ring pie-small animate"
        :style="{ '--p': power.value }"
      >
        <p class="skilles__ring-text">{{ power.value }}%</p>
      </div>
      <p class="skilles__description">
        {{ power.category }}
      </p>
      <button
        class="skilles__btn"
        @click="viewMore = !viewMore"
        :class="{ 'skilles__btn-active': viewMore }"
      ></button>
    </div>
    <div
      class="skilles-element-filling"
      :class="{ 'skilles-element-filling__open': viewMore }"
    >
      <pre class="skills-title">{{ power.title }} </pre>
      <pre class="skills-text">{{ power.body }}</pre>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "SkillesElement",
  props: ["power"],
  setup() {
    return {
      viewMore: ref(false),
    };
  },
};
</script>

<style scoped>
.pie-small {
  --b: 0.1875rem;
  --c: #4a58dd;
  --w: 3.5625rem;
  width: var(--w);
  height: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  place-content: center;
  transform: scale(1, -1);
  flex-shrink: 0;
  z-index: 1;
}

@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.pie-small:before,
.pie-small:after {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.pie-small:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #27282a 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

.pie-small:after {
  inset: calc(50% - 1.78125rem);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 1.87));
}

.skilles__ring-text {
  transform: scale(1, -1);
  font-size: 0.90625rem;
}

.animate {
  animation: --p 1s 0.5s both;
  font-family: "Helvetica";
}

.skilles-element {
  background: linear-gradient(182deg, #101215 0%, #21262e 100%);
  padding: 0.9rem 1.5rem;
  border-radius: 0.875rem;
}

.skilles-element-caption {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.skilles__description {
  font-family: "Helvetica";
  font-size: 1.125rem;
}

.skilles__btn {
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
  width: 1.375rem;
  height: 0.5625rem;
  background: url("@/assets/imgs/statistics/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.skilles__btn-active {
  transform: rotate(180deg);
}

.skilles-element-filling {
  max-height: 0;
  overflow: hidden;
  transition: all 1s ease-in-out;
}

.skilles-element-filling__open {
  max-height: 20rem;
  padding-top: 0.79rem;
}

.skills-title {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fff;
  padding: 0.5rem 0;
}

.skills-title span {
  background-color: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  background-image: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.skills-text {
  font-family: "Helvetica";
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 850px) {
  .pie-small {
    --b: 0.125rem;
    --c: #4a58dd;
    --w: 2.375rem;
  }

  .pie-small:after {
    inset: calc(50% - 1.1875rem);
  }

  .skilles__ring-text {
    font-size: 0.625rem;
  }

  .skilles-element {
    padding: 0.62rem;
    border-radius: 0.625rem;
    background: linear-gradient(182deg, #101215 0%, #21262e 120%);
  }

  .skilles-element-caption {
    gap: 0.5rem;
  }

  .skilles__description {
    font-size: 1rem;
  }

  .skilles__btn {
    width: 0.875rem;
    height: 0.375rem;
  }

  .skilles-element-filling__open {
    max-height: 30rem;
  }

  .skills-title {
    font-size: 0.875rem;
  }

  .skills-text {
    font-size: 0.875rem;
  }
}
</style>
