<template>
  <div class="profle-data">
    <div class="profle-caption">
      <button class="back" @click="closeWindow"></button>
      <h2 class="profle__title">Профиль</h2>
    </div>
    <div class="profile-info" v-if="false">
      <button class="porofile-image">
        <img
            src="@/assets/imgs/photo_camera.svg"
            alt="Выбор фотографии"
            class="porofile-image__mask"
        />
        <img
            src="@/assets/imgs/profile-test.png"
            alt="Фотография"
            class="porofile-image__img"
        />
      </button>
      <div class="porofile-info-filling">
        <p class="porofile__name">Андрей Малышев</p>
        <p class="porofile__login purple-text">@Vrt12345</p>
      </div>
    </div>
    <div class="data-list" v-if="userData">

      <div class="profile-field">
        <p class="profile-field__text">Страна</p>
        <div class="pop-up profile-pop-up">
          <button
              class="select-btn profile__select-btn"
              @click="viewCountries = !viewCountries"
          >
            {{ userData?.sideData?.country ? userData?.sideData?.country : "Выбрать" }}
            <div class="arrow" :class="{ 'arrow-active': viewCountries }"></div>
          </button>
          <div class="select-list profile__select-list" v-if="viewCountries">
            <input
                type="text"
                v-model="searchCountry"
                placeholder="Поиск"
                class="select-element profile__select-element profile-search"
            />
            <button
                class="select-element profile__select-element"
                v-for="(item, index) in Object.keys(countriesList)?.filter((country) =>
                searchCountry
                  ? country
                     ?.toLowerCase()
                      ?.includes(searchCountry.toLowerCase())
                  : true
              )"
                :key="index"
                @click="userData.sideData.country = item"
                :class="{
                'select-element__active': userData?.sideData?.country === item,
              }"
            >
              {{ item }}
            </button>
          </div>
        </div>
      </div>

      <div class="profile-field" v-if="userData?.sideData?.country==='Российская Федерация'">
        <p class="profile-field__text">Регион</p>
        <div class="pop-up profile-pop-up">
          <button
              class="select-btn profile__select-btn"
              @click="viewRegions = !viewRegions"
          >
            {{ userData.region ? userData.region : "Выбрать" }}
            <div class="arrow" :class="{ 'arrow-active': viewRegions }"></div>
          </button>
          <div class="select-list profile__select-list" v-if="viewRegions">
            <input
                type="text"
                v-model="searchRegion"
                placeholder="Поиск"
                class="select-element profile__select-element profile-search"
            />
            <button
                class="select-element profile__select-element"
                v-for="(item, index) in regions?.filter((region) =>
                searchRegion
                  ? region
                      ?.toLowerCase()
                      ?.includes(searchRegion.toLowerCase())
                  : true
              )"
                :key="index"
                @click="userData.region = item"
                :class="{
                'select-element__active': userData.region === item,
              }"
            >
              {{ item }}
            </button>
          </div>
        </div>
      </div>

      <div class="profile-field" v-if="userData?.sideData?.country==='Российская Федерация'">
        <p class="profile-field__text">Населенный пункт</p>
        <div class="pop-up profile-pop-up">
          <button
              class="select-btn profile__select-btn"
              @click="viewCities = !viewCities"
          >
            {{ userData.city ? userData.city : "Выбрать" }}
            <div class="arrow" :class="{ 'arrow-active': viewCities }"></div>
          </button>
          <div class="select-list profile__select-list" v-if="viewCities">
            <input
                type="text"
                placeholder="Поиск"
                class="select-element profile__select-element profile-search"
            />
            <button @click="userData.city = item" class="select-element profile__select-element" v-for="(item, index) in cities?.filter((city) =>
            searchCity
              ? city?.toLowerCase()?.includes(searchCity?.toLowerCase())
              : true
          )"
                    :key="index">
              {{ item }}
            </button>
          </div>
        </div>
      </div>

      <div class="profile-field">
        <p class="profile-field__text">Номер телефона</p>
        <input type="text" ref="el" class="profile-field__input" :value="userData.phone">
      </div>
      <div class="profile-field">
        <p class="profile-field__text">Email</p>
        <input
            type="email"
            v-model.trim="userData.email"
            placeholder="alex.email@mail.ru"
            class="profile-field__input"
        />
      </div>
    </div>
    <button class="save button-purple" @click="saveUserData">Сохранить</button>
  </div>
</template>

<script>
import {computed, inject, ref, watch} from "vue";
import {useIMask} from "vue-imask";
import {errorHandler} from "@/lib/errorHandler";
import countries from "@/lib/data_file.json";

export default {
  name: "ProfileInfo",
  setup(props, {emit}) {
    const store = inject("store");
    const user = computed(() => store.getters.userData);
    const userData = ref(JSON.parse(JSON.stringify(user.value)));


    const {el, unmasked} = useIMask({
      mask: '+{7}(000)000-00-00',
      radix: '.',
    });

    const viewRegions = ref(false),
        searchRegion = ref(null),
        regions = computed(() => {
          if (userData.value?.sideData?.country === 'Российская Федерация') {
            return Object.keys(countries[userData.value?.sideData?.country]);
          }
          return [];
        });

    const viewCities = ref(false),
        searchCity = ref(null)

    const cities = computed(() => {
      if (userData.value?.sideData?.country === 'Российская Федерация' && userData.value?.region) {
        return countries[userData.value?.sideData?.country][userData.value?.region];
      }
      return [];
    });


    const countriesList = ref(countries),
        searchCountry = ref(null),
        viewCountries = ref(false);


    watch(
        () => user.value,
        (val) => {
          userData.value = JSON.parse(JSON.stringify(val));
        }
    );

    watch(
        () => userData.value?.sideData?.country,
        (val) => {
          if (userData.value) return;


          viewCountries.value = false;
          searchCountry.value = null;

          if (val === 'Российская Федерация') {
            userData.value.region = "";
            userData.value.city = "";
          } else {
            userData.value.region = "Другой";
            userData.value.city = "Москва";
          }
        }
    );


    watch(
        () => userData.value?.city,
        () => {
          viewCities.value = false;
          searchCity.value = null;
        }
    );

    watch(
        () => userData.value?.region,
        () => {
          viewRegions.value = false;
          searchRegion.value = null;
        }
    );

    watch(
        () => viewCountries.value,
        (val) => {
          if (val) {
            viewCities.value = false;
            viewRegions.value = false;
          }
        }
    );

    watch(
        () => viewRegions.value,
        (val) => {
          if (val) {
            viewCities.value = false;
            viewCountries.value = false;
          }
        }
    );
    watch(
        () => viewCities.value,
        (val) => {
          if (val) {
            viewRegions.value = false;
            viewCountries.value = false;

          }
        }
    );

    // const findCity = () =>
    //     store
    //         .dispatch("fetchCities", searchCity.value)
    //         .then((val) => (cities.value = [...val]));
    //
    // findCity();
    //
    // watch(() => searchCity.value, findCity);

    const saveUserData = () => {

      const saveData = JSON.parse(JSON.stringify(userData.value));


      if (
          saveData?.sideData?.country === user.value?.sideData?.country &&
          saveData.region === user.value.region &&
          saveData.city === user.value.city &&
          unmasked.value === user.value.phone &&
          saveData.email === user.value.email
      )
        return;

      if (unmasked.value.length !== 11) {
        errorHandler({name: "InvalidField", field: "phone"});
        return;
      } else {
        saveData.phone = unmasked.value;
      }

      if (!/^[\w-\\.]+@[\w-]+\.[a-z]{2,4}$/i.test(userData.value.email)) {
        errorHandler({name: "InvalidField", field: "email"});
        return;
      }

      store.dispatch("saveUserData", {data: saveData});
    };

    return {
      closeWindow: () => emit("closeWindow"),
      userData,
      saveUserData,
      viewRegions,
      viewCities,
      searchRegion,
      searchCity,
      regions,
      cities,
      countriesList,
      searchCountry,
      viewCountries,
      el
    };
  },
};
</script>

<style scoped>
.profle-caption {
  display: flex;
  gap: 0.88rem;
}

.back {
  width: 1.75rem;
  height: 1.75rem;
  background: url("@/assets/imgs/back.svg");
  background-size: cover;
}

.profle__title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.profile-info {
  margin-top: 1.75rem;
  display: flex;
  gap: 0.87rem;
  align-items: center;
}

.porofile-image {
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.188rem solid #fff;
  border-radius: 0.625rem;
}

.porofile-image__mask {
  position: absolute;
}

.porofile-image__img {
  border-radius: 0.425rem;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.porofile__name {
  font-size: 0.875rem;
  line-height: 1.75rem;
}

.porofile__login {
  margin-top: 0.19rem;
  font-size: 0.75rem;
  line-height: normal;
}

.data-list {
  margin-top: 1.75rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.profile-search {
  background: none;
}

.save {
  margin-top: 3.2rem;
  padding: 1rem 2.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: normal;
  border-radius: 0.625rem;
}

.arrow {
  width: 1.25rem;
  height: 1.25rem;
  background: url("@/assets/imgs/arrow-bottom-grey.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-active {
  transform: rotate(180deg);
}

@media (max-width: 850px) {
  .save {
    margin-top: 2.3rem;
    padding: 0.75rem;
    max-width: 100%;
    font-size: 1rem;
    width: 100%;
    text-align: center;
  }
}
</style>
