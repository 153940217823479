import {createStore} from 'vuex'
import {user} from "./user";
import {application} from "./application";
import {missions} from "@/store/missions";
import {calendar} from "@/store/calendar";


// creating Vuex store instance
const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user, application, missions, calendar
    }
});

//store.dispatch('loadUserToken');

export default store;