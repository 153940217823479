import store from '@/store';
import {appConfig} from "@/lib/appConfig";


// check if user has token - authorized

function checkRequiredFields(next, to, callback) {
    if (!appConfig.appState.free) {

        const user = store.getters.userData;

        if (!['account_paid', 'action_started_missions_passed']?.includes(user?.status)) {
            if (to?.name !== 'PaymentEndPage') {
                next({name: 'PaymentEndPage'})
            } else {
                next();
            }
            return
        }


        if (!user?.firstName || !user?.secondName || !user?.email || !user?.region || !user?.phone || !user?.birthDate) {
            if (to?.name !== 'registration') {
                next({name: 'registration'})
            } else {
                next()
            }
            return;
        }
    }
    if (callback) {
        callback();
    }
}


export const canEnterToSuccessPayment = async (next, from) => {
    if (!appConfig.appState.free) {
        await store.dispatch('loadUserTokenAndData');
        if (from.name !== 'PaymentEndPage') {
            return next({name: 'registration'});
        }
        next();
    } else {
        next({name: 'landing'});
    }
}

export const canEnterToUserPortal = async (next, to) => {
    await store.dispatch('loadUserTokenAndData');
    checkRequiredFields(next, to, function () {
        next();
    });

}
export const canEnterToPayment = async (next, to, from) => {
    await store.dispatch('loadUserTokenAndData');
    checkRequiredFields(next, to, function () {
        next(from);
    });

}


export const canEnterToAuthorization = async (next, to) => {
    await store.dispatch('loadUserTokenAndData', true);

    if (!store.getters.token) {
        next();
    } else {
        checkRequiredFields(next, to, function () {
            next({name: 'missions'});
        });

    }
}


export const canEnterMission = async (to, from, next) => {
    await store.dispatch('loadUserTokenAndData', true);
    checkRequiredFields(next, to, function () {
        const missionIndex = store.getters.missions.map(item => item.missionId).indexOf(Number(to.params.missionId));
        if (missionIndex === -1) next(from);
        else next();
    });
}

export const canEnterLanding = async () => {
    await store.dispatch('loadUserTokenAndData', true);
    // if (store.getters.token) next({name: 'missions'})
}


export const allMissionPassed = () => {
    for (const key in store.getters.userData?.missions) {
        if (!store.getters.userData.missions[key])
            return false
    }
    return true;
}
