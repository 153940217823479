<template>
  <div class="bg"></div>
  <div class="training">

    <div class="training-wrapper">
      <div class="training-content">
        <div class="greetings" v-if="educationStep !== 9"
             :style="{ transform: `translateX(${steps[educationStep]?.translateX}) translateY(${steps[educationStep]?.translateY})` }">
          <img src="@/assets/imgs/robot-big.png" v-if="educationStep !== 8" alt="" class="robot desktop">
          <img src="@/assets/imgs/robot-pay.png" alt="" class="robot robot--last desktop" v-if="educationStep === 8">
          <div class="content">
            <div class="message">
              <img src="@/assets/imgs/triangle-training.svg" alt="" class="triangle desktop">

              <p class="step purple-text">{{ educationStep + 1 }} из {{ steps?.length }}</p>
              <pre class="message__text desktop">{{ steps[educationStep]?.text }}</pre>
              <div class="message__content mobile">
                <img src="@/assets/imgs/robot-big.png" v-if="educationStep !== 8" alt="" class="robot ">
                <img src="@/assets/imgs/robot-pay.png" alt="" class="robot robot--last " v-if="educationStep === 8">
                <pre class="message__text ">{{ steps[educationStep]?.text }}</pre>
              </div>
            </div>
            <div class="actions">
              <button class="actions__btn" @click="prevStep" v-if="educationStep">Назад</button>
              <button class="actions__btn" @click="nextStep">Далее</button>
            </div>
          </div>
        </div>
        <!--        <div class="payment" v-if="educationStep === 9 && canPayment">-->
        <!--          <div class="payment-caption">-->
        <!--            <p class="payment-caption__text">Последний шаг!</p>-->
        <!--            <button class="payment-caption__help purple-text" @click="restartEducation">-->
        <!--              <img src="@/assets/imgs/nav.svg" alt="" class="payment-caption__help-icon">-->
        <!--              Навигация-->
        <!--            </button>-->
        <!--            <button class="close" @click="finishTraining">-->
        <!--              <img src="@/assets/imgs/close-grey.svg" alt="" class="close__img"/>-->
        <!--            </button>-->
        <!--          </div>-->
        <!--          <div class="info">-->
        <!--            <p class="info__text">Мы не обсудили с тобой один важный момент! <br> <br> Для того чтобы <span>получить-->
        <!--                полноценный</span>-->
        <!--              доступ ко всей информации о тебе, а также возможность запросить целевой договор на обучение и потратить-->
        <!--              накопленные баллы, тебе необходимо оплатить доступ.</p>-->
        <!--            <img src="@/assets/imgs/info-img.png" alt="" class="info__img">-->
        <!--          </div>-->
        <!--          <p class="pay-title">Произвести оплату</p>-->
        <!--          <div class="pay">-->
        <!--            <div class="pay-caption">-->
        <!--              <img src="@/assets/imgs/robot-face.png" alt="" class="pay-caption__face">-->
        <!--              <p class="pay-caption__text">Всего</p>-->
        <!--              <p class="pay-caption__text mar-left-auto">2999 ₽</p>-->
        <!--            </div>-->
        <!--            <div class="finish">-->
        <!--              <div class="border"></div>-->
        <!--              <div class="finish-content">-->
        <!--                <p class="finish-content__text">Итого:</p>-->
        <!--                <p class="finish-content__price">2999 ₽</p>-->
        <!--              </div>-->
        <!--              <button class="finish__btn" @click="makePay">{{-->
        <!--                  canPayment ? 'Оплатить' : 'Закрыть'-->
        <!--                }}-->
        <!--              </button>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="payment" v-if="educationStep === 9 && !canPayment">-->
        <!--          <div class="payment-caption">-->
        <!--            <p class="payment-caption__text">Навигация завершена</p>-->
        <!--            <button class="close" @click="finishTraining">-->
        <!--              <img src="@/assets/imgs/close-grey.svg" alt="" class="close__img" />-->
        <!--            </button>-->
        <!--          </div>-->
        <!--          &lt;!&ndash;          <div class="info">&ndash;&gt;-->
        <!--          &lt;!&ndash;            <p class="info__text">Мы не обсудили с тобой один важный момент! <br> <br> Для того чтобы <span>получить полноценный</span>&ndash;&gt;-->
        <!--          &lt;!&ndash;              доступ ко всей информации о тебе, а также возможность запросить целевой договор на обучение и потратить&ndash;&gt;-->
        <!--          &lt;!&ndash;              накопленные баллы, тебе необходимо оплатить доступ.</p>&ndash;&gt;-->
        <!--          &lt;!&ndash;            <img src="@/assets/imgs/info-img.png" alt="" class="info__img">&ndash;&gt;-->
        <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
        <!--          &lt;!&ndash;          <p class="pay-title">Произвести оплату</p>&ndash;&gt;-->
        <!--          <div class="pay">-->
        <!--            &lt;!&ndash;            <div class="pay-caption">&ndash;&gt;-->
        <!--            &lt;!&ndash;              <img src="@/assets/imgs/robot-face.png" alt="" class="pay-caption__face">&ndash;&gt;-->
        <!--            &lt;!&ndash;              <p class="pay-caption__text">Всего</p>&ndash;&gt;-->
        <!--            &lt;!&ndash;              <p class="pay-caption__text mar-left-auto">2999 ₽</p>&ndash;&gt;-->
        <!--            &lt;!&ndash;            </div>&ndash;&gt;-->
        <!--            <div class="finish">-->
        <!--              &lt;!&ndash;              <div class="border"></div>&ndash;&gt;-->
        <!--              &lt;!&ndash;              <div class="finish-content">&ndash;&gt;-->
        <!--              &lt;!&ndash;                <p class="finish-content__text">Итого:</p>&ndash;&gt;-->
        <!--              &lt;!&ndash;                <p class="finish-content__price">2999 ₽</p>&ndash;&gt;-->
        <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
        <!--              <button class="finish__btn" @click="finishTraining">-->
        <!--                Закрыть-->
        <!--              </button>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </div>

</template>

<script>
import {useStore} from "vuex";
import {onBeforeUnmount, onMounted, ref, watch} from "vue";
// import {appConfig} from "@/lib/appConfig";

export default {
  name: "LoaderElement",

  props: ['educationStep', 'status'],
  setup(props) {
    const store = useStore();

    const timer = ref(null);

    // const canPayment = computed(() => {
    //   return !appConfig.appState.free && props.status !== 'account_paid'
    // })

    const steps = [
      {
        text: "Мы в Личном кабинете с твоими результатами.\nСейчас я расскажу тебе, где что находится.",
        translateX: "0",
        translateY: "0",
      },
      {
        text: "Поделись с друзьями своим профессиональным аватаром, скачай сертификат о прохождении и уникальную информацию о тебе из личного кабинета.",
        translateX: "0",
        translateY: "0",
      },
      {
        text: "Здесь ты можешь вдохновляться мечтами о своём будущем",
        translateX: "-15rem",
        translateY: "0",
      },
      {
        text: "Я подобрал для тебя несколько интересных вариантов, где ты сможешь получить профессию. Знакомься с возможностями, которые ты получишь там.",
        translateX: "4rem",
        translateY: "0rem",
      },
      {
        text: "Познакомься с тем, что тебя мотивирует, твоими суперспособностями и профессиональными навыками, которые тебе нужно развивать для будущей карьеры.",
        translateX: "0",
        translateY: "0",
      },
      {
        text: "Знакомься – здесь будущие варианты развития твоей карьеры. Здесь я расскажу тебе, где ты сможешь работать в будущем, какой уровень зарплаты тебя ждёт и кто может оплатить тебе обучение (дать целевой договор).",
        translateX: "-15rem",
        translateY: "0",
      },
      {
        text: "Смотри, какие профессии подходят именно тебе. Уверен, что в них ты сможешь раскрыть свой потенциал, принести пользу людям и добиться впечатляющих высот в карьере.",
        translateX: "-15rem",
        translateY: "0",
      },
      {
        text: "Здесь персональная подборка мероприятий исходя из твоих интересов. Начни строить свой профессиональный путь уже сейчас.",
        translateX: "-16rem",
        translateY: "0",
      },
      {
        text: "Вперёд – тратить баллы! Смотри, что у тебя на балансе, и трать на что хочешь. А если захочешь пообщаться о бесплатном обучении – просто нажми на «Чат с помощником». И не забудь загрузить свою фотку.",
        translateX: "5rem",
        translateY: "0",
      },
    ]


    const nextStep = () => store.commit('setEducationStep', props.educationStep + 1);


    const prevStep = () => store.commit('setEducationStep', props.educationStep - 1);


    const finishTraining = () => store.dispatch('finishTraining');

    // const restartEducation = () => {
    //   finishTraining();
    //   setTimeout(() => {
    //     store.commit('setEducationStep', 0)
    //   }, 100);
    // }


    // const makePay = () => {
    //   if (!canPayment.value) {
    //     return finishTraining();
    //   }
    //   store.dispatch('makePay').then(finishTraining)
    // }


    watch(() => props.educationStep, (val) => {
      clearTimeout(timer.value);

      if (val >= 9) {
        return finishTraining();
      }
      if (val && val < 9) {
        timer.value = setTimeout(nextStep, 5000);
      }

    });

    timer.value = setTimeout(nextStep, 5000);


    onMounted(() => {
      if (document.getElementById("video-css")) return;
      const el = document.createElement("link");
      el.href = "../style/video.css";
      el.rel = "stylesheet";
      el.id = "video-css";
      document.body.append(el);
    });

    onBeforeUnmount(() => document.getElementById("video-css")?.remove())


    return {
      steps,
      nextStep,
      prevStep,
      finishTraining,
    }

  }

};
</script>

<style scoped>
.training {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  transition: 0.5s all ease-in-out;
  z-index: 8;
}

.bg {
  position: fixed;
  z-index: 6;
  width: 100vw;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.59);
}


.training-wrapper {
  padding: 3rem 1.5rem;
  display: flex;
  width: 100vw;
  overflow: auto;
  height: 100vh;
  left: 0;
  top: 0;
  transition: 0.5s all ease-in-out;
}

.training-content {
  margin: auto;
  max-width: 60vw;
  width: 100%;
  position: relative;
}

.robot {
  margin-top: 5rem;
  width: 16.125rem;
  animation: appearance 0.5s ease-in-out;
}

.greetings {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  position: relative;
  z-index: 10;
  justify-content: center;
}

.content {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.message {
  border-radius: 1.5rem;
  background: #FFF;
  position: relative;
  padding: 1rem 1rem 3rem 2.5rem;
  max-width: 100%;
  width: 100%;
}

.triangle {
  position: absolute;
  width: 4.25rem;
  bottom: 1rem;
  left: -3rem;
}

.step {
  font-size: 1rem;
  font-weight: 700;
  line-height: 110%;
  text-align: right;
}

.message__text {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: normal;
  color: #000;
}

.actions {
  max-width: 100%;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1.5rem;
  display: flex;
  gap: 0.5rem;
}

.actions__btn {
  max-width: 13.9375rem;
  width: 100%;
  text-align: center;
  padding: 0.81rem;
  border-radius: 0.5rem;
  background: linear-gradient(150.98deg, #ad4fea 0%, #4a58dd 119.7%);
  color: #FFF;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.7;
}

.actions__btn:hover {
  opacity: 1;
}

.payment {
  margin: auto;
  padding: 2rem;
  border-radius: 2rem;
  background: #E5E7EE;
  position: relative;
  pointer-events: all;
  max-width: 52.125rem;
  width: 100%;
  pointer-events: all;
  font-family: "Roboto", sans-serif;
  animation: appearance 0.5s ease-in-out;
}

.payment-caption {
  display: flex;
  gap: 1.2rem;
  align-items: center;
  justify-content: space-between;
}

.payment-caption__text {
  color: #000;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.25rem;
}


.close__img {
  width: 2.8125rem;
  height: 2.8125rem;
}

.info {
  margin-top: 3rem;
  border-radius: 1rem;
  background: #FFF;
  position: relative;
  padding: 2.62rem 10.84rem 2.69rem 1.88rem;
  align-items: center;
  display: flex;
}

.info__text {
  color: #131313;
  font-size: 1.5rem;
  line-height: 2rem;
}

.info__text span {
  color: #4A58DD;
  font-weight: 700;
}

.info__img {
  position: absolute;
  right: -0.81rem;
  width: 13.89538rem;
}

.pay-title {
  margin-top: 1.94rem;
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.pay {
  margin-top: 1.26rem;
  padding: 1.5rem 2.81rem;
  border-radius: 1rem;
  background: #FFF;
  position: relative;
  z-index: 10;
}

.pay-caption {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.pay-caption__face {
  width: 5.8125rem;
}

.pay-caption__text {
  color: #21262E;
  font-size: 2.5rem;
  line-height: 1.25rem;
}

.mar-left-auto {
  margin-left: auto;
}

.finish {
  margin-top: 2.06rem;

}

.border {
  border-radius: 0.125rem;
  background: #E5E7EE;
  height: 1px;
  width: 100%;
}

.finish-content {
  margin-top: 2.31rem;
  display: flex;
  justify-content: space-between;
}

.finish-content__text {
  color: #000;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.finish-content__price {

  font-size: 3rem;
  font-weight: 700;

  background: linear-gradient(92deg, #BE5AFF 14.66%, #949EFF 72.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.finish__btn {
  margin-top: 2.13rem;
  padding: 1.53rem;
  border-radius: 0.625rem;
  background: linear-gradient(92deg, #AD4FEA 4.41%, #4A58DD 72.27%);
  text-align: center;
  color: #FFF;
  font-size: 2rem;
  font-weight: 700;
  line-height: 100%;
  max-height: 100%;
  width: 100%;
}

.payment-caption {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
}

.payment-caption__text {
  color: #000;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.payment-caption__help {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 500;
  font-size: 20px;
}

.payment-caption__help-icon {
  width: 3.4rem;
}

.close {
  margin-left: auto;
}

@media (max-width: 850px) {
  .training-wrapper {
    height: unset;
    flex-direction: column;
  }

  .training-content {
    margin: auto;
    max-width: 100%;
    display: flex;
    align-items: center;
  }

  .training-wrapper {
  }


  .greetings {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 1.5rem;
    transform: translateX(0px) translateY(0px) !important;
  }

  .content {
    margin-bottom: 1rem;
  }

  .message {
    border-radius: 1.5rem;
    background: #FFF;
    position: relative;
    padding: 1rem 1rem 1.5rem 1.1rem;
    max-width: 100%;
    width: 100%;
    gap: 0.25rem;
  }

  .robot {
    margin: 0;
    width: 3rem;
  }

  .message__content {
    display: flex;
    align-items: center;
    gap: 0.81rem;
  }

  .triangle {
    position: absolute;
    width: 2.125rem;
    bottom: -2.35rem;
    left: 0.26rem;
  }

  .step {
    font-size: 0.75rem;
  }

  .message__text {
    margin-top: 0.25rem;
    font-size: 0.875rem;
  }

  .actions {
    margin-top: 0.5rem;
  }

  .actions__btn {
    max-width: 100%;
    width: 100%;
    text-align: center;
    padding: 0.75rem;
    font-size: 0.75rem;
  }

  .robot--last {
    z-index: 2;
    bottom: 1.5rem;
  }

  .payment {
    padding: 1rem;
    border-radius: 1rem;

  }

  .payment-caption {
    gap: 0.6rem;

  }

  .payment-caption__text {
    font-size: 1.25rem;
  }


  .close__img {
    width: 1.5rem;
    height: 1.5rem;
  }

  .info {
    margin-top: 1.5rem;
    border-radius: 0.5rem;
    background: #FFF;
    position: relative;
    padding: 1rem 5rem 1rem 1rem;
  }

  .info__text {
    color: #131313;
    font-size: 1rem;
    line-height: normal;
  }

  .info__img {
    position: absolute;
    right: -0.81rem;
    width: 5.89538rem;
  }

  .pay-title {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: normal;
  }

  .pay {
    margin-top: 1.26rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background: #FFF;
  }

  .pay-caption {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }

  .pay-caption__face {
    width: 3rem;
  }

  .pay-caption__text {
    font-size: 1.5rem;
    line-height: normal;
  }

  .finish {
    margin-top: 1rem;
  }

  .finish-content {
    margin-top: 1rem;
  }

  .finish-content__text {
    font-size: 1.5rem;
    line-height: normal;
  }

  .finish-content__price {
    font-size: 1.5rem;
    line-height: normal;
  }

  .finish__btn {
    margin-top: 1rem;
    padding: 0.75rem;
    font-size: 1rem;
    line-height: normal;
  }

  button:hover {
    opacity: 1 !important;
  }

  .payment-caption__help-icon {
    width: 1.5rem;
  }

  .payment-caption__help {
    font-size: 1rem;
    gap: 0.3rem;
  }
}
</style>