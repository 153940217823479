import {errorHandler} from "@/lib/errorHandler";


// function gets async function with http request, execute it and validate by response status code
export async function defaultHttpResHandler(doRequest, params = {}) {
    let resStatus;
    let data;
    try {
        const req = await doRequest();
        if (req.status === 401 || req.status === 403 || req.status === 400) {
            errorHandler({status: req.status, data: req.data, ...params})
            return req;
        }
        return req;
    } catch (e) {
        resStatus = e?.response?.status;
        data = e?.response?.data?.statusCode;
        return {data, status: resStatus};

    }
    // switch (resStatus) {
    //     case 400:
    //         switch (statusCode) {
    //             case 'AuthFailed':
    //                 throw new AuthFailedError();
    //             case 'InvalidCredentialsError':
    //                 throw new InvalidCredentialsError();
    //             case 'ValidationError':
    //                 throw new ValidationError();
    //         }
    //         break;
    //     case 500:
    //         throw new InternalServerError();
    // }
    // throw new InvalidCredentialsError();
}