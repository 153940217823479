<template>
  <div class="loader">
    <div class="loader-filling">
<!--      <p class="loader-text">Идёт загрузка</p>-->
<!--      <p class="loader-text fs10">(Загрузка может занимать около 10 минут)</p>-->
      <img src="@/assets/imgs/video/loader.svg" alt="" class="loader-img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LoaderElement",
};
</script>

<style scoped>
.loader {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 1rem 1.5rem;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
}
.loader-filling {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: auto;
  align-items: center;
  text-align: center;
}
.loader-text {
  font-size: 2rem;
}
.fs10{
  font-size: 1.3rem;

}
.loader-img {
  width: 6rem;
}
@media (max-width: 750px) {
  .loader-text {
    font-size: 1.5rem;
  }
  .loader-filling {
    gap: 1.5rem;
  }
  .loader-img {
    width: 4rem;
  }
}
</style>
