<template>
  <div class="profle-education">
    <div class="profle-caption">
      <button class="back"></button>
      <h2 class="profle__title">Образование</h2>
    </div>
    <div class="education-list">
      <div class="education-list-wrapper">
        <p class="education-list__title">Среднее и доп.</p>
        <div class="education-list__border"></div>
        <button class="education-list__element">
          МБОУ «Средняя общеобразовательная школа № 1»
        </button>
      </div>
      <div class="education-list-wrapper">
        <p class="education-list__title">Высшее</p>
        <div class="education-list__border"></div>
        <button class="education-list__element">
          Казанский федеральный университет
        </button>
      </div>
    </div>
    <p class="education__description">Расскажите, где вы учились</p>
    <button class="save button-purple">Добавить школу</button>
  </div>
</template>

<script>
export default {
  name: "ProfileEducationSelect",
};
</script>

<style scoped>
.profle-education {
  display: flex;
  flex-direction: column;
}
.profle-caption {
  display: flex;
  gap: 0.88rem;
}
.back {
  width: 1.75rem;
  height: 1.75rem;
  background: url("@/assets/imgs/back.svg");
  background-size: cover;
}
.profle__title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
}
.education-list {
  margin-top: 0.88rem;
  display: flex;
}
.education-list-wrapper {
  padding: 1rem;
  max-width: 100%;
  width: 100%;
}
.education-list__title {
  padding: 0.75rem;
  color: #fff;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25rem;
  max-width: 100%;
  width: 100%;
}
.education-list__border {
  height: 1px;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  width: 100%;
  max-width: 100%;
}
.education-list__element {
  padding: 0.75rem;
  text-align: center;
  margin-top: 1rem;
  font-size: 1rem;
  max-width: 100%;
  width: 100%;
}
.education__description {
  margin-top: 3.87rem;
  padding: 0.75rem;
  color: rgba(255, 255, 255, 0.35);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: -0.02rem;
}
.save {
  margin: 1.69rem auto 0 auto;
  padding: 1rem 2.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: normal;
  border-radius: 0.625rem;
}
</style>
