<template>
  <div class="profle-data">
    <div class="profle-caption">
      <button class="back" @click="closeWindow"></button>
      <h2 class="profle__title">Образование</h2>
    </div>
    <div class="data-list" v-if="userData">
      <div class="profile-field">
        <p class="profile-field__text">Образовательное учреждение</p>
        <div class="pop-up profile-pop-up">
          <button class="select-btn profile__select-btn" @click="viewEducationType = !viewEducationType">
            {{ userData.education ? userData.education : "Выбрать" }}
            <div class="arrow" :class="{ 'arrow-active': viewEducationType }"></div>
          </button>
          <div class="select-list profile__select-list" v-if="viewEducationType">
            <button @click="userData.education = item" v-for="(item, index) in educations" :key="index"
                    class="select-element profile__select-element"
                    :class="{ 'select-element__active': userData.education === item }">
              {{ item }}
            </button>
          </div>
        </div>
      </div>
      <div class="profile-field">
        <p class="profile-field__text">Название образовательного учреждения</p>
        <input type="text" v-model="userData.educationName" class="profile-field__input"/>
      </div>
      <div class="profile-field">
        <p class="profile-field__text">Класс/курс</p>
        <div class="pop-up profile-pop-up">
          <button class="select-btn profile__select-btn" @click="viewCourses = !viewCourses">
            {{ userData.course ? userData.course : "Выбрать" }}
            <div class="arrow" :class="{ 'arrow-active': viewCourses }"></div>
          </button>
          <div class="select-list profile__select-list" v-if="viewCourses">
            <button v-for="(item, index) in courses" :key="index" @click="userData.course = item"
                    class="select-element profile__select-element"
                    :class="{ 'select-element__active': userData.course === item }">
              {{ item }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <button class="save button-purple" @click="saveUserData">Сохранить</button>
  </div>
</template>

<script>
import {computed, inject, ref, watch} from "vue";

export default {
  name: "ProfileEducationSettings",
  setup(props, {emit}) {
    const store = inject("store");
    const user = computed(() => store.getters.userData);
    const userData = ref(JSON.parse(JSON.stringify(user.value)));

    const courses = [
      "8 класс",
      "9 класс",
      "10 класс",
      "11 класс",
      "1 курс",
      "2 курс",
      "3 курс",
      "4 курс",
      "5 курс"
    ];

    const educations = [
      "Общеобразовательная организация (школа)",
      "Профессиональная образовательная организация (ссуз)",
      "Образовательная организация высшего образования (вуз)",
    ];

    const viewEducationType = ref(false);

    const viewCourses = ref(false);

    watch(
        () => user.value,
        (val) => {
          userData.value = JSON.parse(JSON.stringify(val));
        }
    );

    watch(
        () => userData.value?.education,
        () => {
          viewEducationType.value = false;
        }
    );

    watch(
        () => userData.value?.course,
        () => {
          viewCourses.value = false;
        }
    );

    watch(
        () => viewCourses.value,
        (val) => {
          if (val) viewEducationType.value = false;
        }
    );

    watch(
        () => viewEducationType.value,
        (val) => {
          if (val) viewCourses.value = false;
        }
    );

    const saveUserData = () => {
      if (
          userData.value.education === user.value.education &&
          userData.value.educationName === user.value.educationName &&
          userData.value.course === user.value.course &&
          userData.value.promocode === user.value.promocode
      )
        return;

      store.dispatch("saveUserData", {data: userData.value});
    };

    return {
      userData,
      closeWindow: () => emit("closeWindow"),
      saveUserData,

      courses,
      educations,
      viewCourses,
      viewEducationType,
    };
  },
};
</script>

<style scoped>
.profle-caption {
  display: flex;
  gap: 0.88rem;
}

.back {
  width: 1.75rem;
  height: 1.75rem;
  background: url("@/assets/imgs/back.svg");
  background-size: cover;
}

.profle__title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.data-list {
  margin-top: 1.75rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.profile-search {
  background: none;
}

.save {
  margin-top: 3.2rem;
  padding: 1rem 2.25rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: normal;
  border-radius: 0.625rem;
}

.arrow {
  width: 1.25rem;
  height: 1.25rem;
  background: url("@/assets/imgs/arrow-bottom-grey.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow-active {
  transform: rotate(180deg);
}

@media (max-width: 850px) {
  .save {
    margin-top: 2.3rem;
    padding: 0.75rem;
    max-width: 100%;
    font-size: 1rem;
    width: 100%;
    text-align: center;
  }
}
</style>
