<template>
  <div class="payment-wrapper">
    <div class="payment" ref="modal" v-if="!viewMarathonModal&&!viewCourseModal">
      <button class="close" @click="closeModal">
        <img src="@/assets/imgs/close.svg" alt="" class="close__img"/>
      </button>
      <p class="text">Потратить баллы</p>
      <div class="actions">
        <button class="actions-button button-purple" @click="viewCourseModal=!viewCourseModal">
          <span class="coin-wrapper coin-wrapper--small">
            <span class="coin__number coin__number--small">250</span>
          </span>
          <span class="action-button--text">
          Потратить баллы на онлайн-курс
            </span>
        </button>
        <button class="actions-button button-purple">
          <span class="coin-wrapper coin-wrapper--small">
            <span class="coin__number coin__number--small">250</span>
          </span>
          <span class="action-button--text" @click="viewMarathonModal = !viewMarathonModal">
            Потратить баллы на 50%-ю скидку
            на доступ к марафону по выбору профессии
          </span>
        </button>
      </div>
    </div>
    <marathon-modal ref="marathonModal" v-if="viewMarathonModal" @close="viewMarathonModal=false"
                    @save="savePaymentData"></marathon-modal>
    <course-modal ref="coursesModal" v-if="viewCourseModal" @close="viewCourseModal=false"
                  @save="savePaymentData"></course-modal>
  </div>
</template>

<script>
import {ref, shallowRef} from "vue";
import MarathonModal from "@/components/profile/MarathonModal.vue";
import CourseModal from "@/components/profile/CourseModal.vue";
import {onClickOutside} from "@vueuse/core";
import {useStore} from "vuex";

export default {
  name: "PaymentElement",
  components: {MarathonModal, CourseModal},
  setup(props, {emit}) {

    const store = useStore();

    const modal = ref(null);
    const marathonModal = ref(null);
    const coursesModal = ref(null);

    const viewMarathonModal = shallowRef(false);
    const viewCourseModal = shallowRef(false);

    // const userData = computed(() => store.getters.userData);


    const closeModal = () => {
      store.commit("setPaymentByScoreModal", false);
    };
    // const closeWindow = (event) => {
    //   if (document.getElementById("payment")?.contains(event?.target) || document.getElementById("marathon")?.contains(event.target) || document.getElementById("courses")?.contains(event.target)) return
    //   closeModal();
    // };


    // if (!appConfig.appState.free && userData.value?.status !== 'account_paid') {
    //
    //   closeModal();
    //   store.commit('setPaymentByMoneyModal', true);
    //
    // }

    onClickOutside(modal, closeModal);
    onClickOutside(marathonModal, () => {
      viewMarathonModal.value = false
    });
    onClickOutside(coursesModal, () => {
      viewCourseModal.value = false
    });


    return {
      modal,
      marathonModal,
      coursesModal,
      viewMarathonModal,
      viewCourseModal,
      closeModal,

      savePaymentData({target, phone, email, name}) {
        emit('savePaymentData', {target, phone, email, name});
      }
    };
  },
};
</script>

<style scoped>
.payment-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  z-index: 13;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  padding: 3rem;
  display: flex;
  scrollbar-width: none;
}

.payment {
  padding: 1.875rem 1.875rem 3.75rem 1.875rem;
  border-radius: 2rem;
  background: #101215;
  position: relative;
  pointer-events: all;
  max-width: 35.9375rem;
  width: 100%;
  pointer-events: all;
  margin: auto;
}

.coin-wrapper {
  width: 4.375rem;
  height: 4.375rem;
  flex-shrink: 0;
}

.coin__number {
  font-size: 1.45831rem;
  width: 3.79169rem;
  height: 3.79169rem;
  border: 0.1458rem solid #585a5e;
}


.text {
  font-size: 2.43019rem;
  font-weight: 700;
  line-height: normal;
}


.price__number span {
  position: relative;
  background-color: #858585;
  background-image: #858585;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.price__number span::after {
  content: "";
  display: block;
  position: absolute;
  width: 6.9375rem;
  height: 0.125rem;
  top: 0;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  left: -0.88rem;
  top: 1rem;
  transform: rotate(-1deg);
}

.actions {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.88rem;
}

.actions-button {
  max-width: 100%;
  width: 100%;
  padding: 0.62rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 1.3rem;
  font-size: 1.1875rem;
  border-radius: 0.625rem;

}

.action-button--text {
  line-height: normal;
}

.close {
  position: absolute;
  right: -3rem;
  top: 0;
  padding: 0.62rem;
  cursor: pointer;
}

.close__img {
  width: 1.75rem;
  height: 1.75rem;
}

.coin-wrapper--small {
  width: 3rem;
  height: 3rem;
  display: flex;
  flex-shrink: 0;
}

.coin__number--small {
  width: 2.6rem;
  height: 2.6rem;
  font-size: 1rem;
}

@media (max-width: 850px) {
  .payment-wrapper {
    padding: 2rem 1rem;
    background: rgba(0, 0, 0, 0.66);
  }

  .payment {
    padding: 1.5rem;
    border-radius: 0.625rem;
    max-width: 25rem;
  }

  .close {
    right: 0;
    top: -3rem;
  }

  .text {
    font-size: 1.5rem;
    margin-left: auto;
  }

  .coin-wrapper {
    width: 2.42206rem;
    height: 2.42206rem;
  }

  .coin__number {
    font-size: 0.80738rem;
    width: 2.09913rem;
    height: 2.05031rem;
    border: 1.29px solid #585a5e;
  }


  .actions {
    margin-top: 1.5rem;
    gap: 1.5rem;
  }

  .actions-button {
    padding: 0.44rem 1rem;
    height: auto;
    font-size: 0.875rem;
    gap: 0.5rem;
  }

  .coin-wrapper--small {
    width: 2rem;
    height: 2rem;
    display: flex;
    flex-shrink: 0;
  }

  .coin__number--small {
    width: 1.6rem;
    height: 1.6rem;
    font-size: 0.7rem;
  }

}
</style>
