<template>
  <div class="filling" :class="{ 'step': step === 6 }">
    <img src="@/assets/imgs/video/bg-7.webp" alt="" class="bg" />

    <div class="list-wrapper">
      <img src="@/assets/imgs/video/decoration-7-7.webp" alt="" class="decoration-7 decoration" />
      <div class="list">
        <p class="list-element list-element-1 " v-for="item in superpowers" :key="item.power"
          :class="{ 'animate-2': viewAnimate && step === 6 }">
          {{ item.power }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
  name: "VideoElement7",
  props: ["superpowers", 'step'],
  setup() {
    const viewAnimate = ref(false);

    onMounted(() => setTimeout(() => viewAnimate.value = true, 20));
    return {
      viewAnimate
    }
  }
};
</script>

<style scoped>
.filling {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.decoration {
  position: absolute;
}

.list {
  position: absolute;
  display: flex;
  z-index: 2;
  top: 31.82vw;
  left: 4.38vw;

}

.list-element {
  color: #fff;
  text-align: center;
  font-size: 2.5vw;
  font-weight: 500;
  line-height: 143.5%;
  opacity: 0;
  position: absolute;
  width: 28vw;
}

.list-element:nth-child(1) {
  left: 0;
  transition: all 0.5s linear;
}

.list-element:nth-child(2) {
  left: 25vw;
  transition: all 1s linear;
}

.animate {
  opacity: 1;
}

.decoration-7 {
  width: 47.19vw;
  height: 5.89vw;
  left: -0.21vw;
  top: 22.5vw;
}


@keyframes content-open {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.animate-2 {
  animation: content-open 1s 0.5s both;
  will-change: opacity;
}
</style>
