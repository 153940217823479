import {
    checkUserEmail, createPostVk,
    downloadCertificate,
    downloadPresentation,
    downloadVideo,
    fetchCities, fetchPaymentStatus, fetchPostPreview,
    fetchRegions,
    fetchStudentStatistic, fetchStudentStatisticForVideo,
    fetchUserAnswers,
    fetchUserData, fetchVkToken, makePay,
    saveAnswers,
    saveUserData, sendEmailPost,
    submitAuthorization,
    submitRegistration, submitRegistrationPayment,
} from "./serverInterationMethods";
import router from "@/router";
import dayjs from "dayjs";
import {errorHandler} from "@/lib/errorHandler";
import {appConfig} from "@/lib/appConfig";
// import {sleep} from "@/lib/time";


// store with all authenticated user data
export const user = {
    state: {
        // authorization user token for api
        token: null,
        registrationData: null,
        regions: null,
        userData: null,
        role: null,
        studentStatistic: null,
        pickedCompany: null,
        avatarFile: null,

        educationStep: null,
        viewVkShareModal: false,

    },
    mutations: {
        setViewVkShareModal(state, data) {
            state.viewVkShareModal = data;
        },
        setAvatarFile(state, data) {
            state.avatarFile = data;
        },
        setPickedCompany(state, data) {
            state.pickedCompany = data;
        },
        // setting token state
        setToken(state, data) {
            state.token = data;
        },
        setRole(state, data) {
            state.role = data;
        },
        setUserData(state, data) {
            state.userData = data;
            if (!data) return;
            if (!state.userData.avatarUrl?.trim()) {
                state.userData.avatarUrl = require('@/assets/imgs/user-placeholder.png');
            }
            state.userData.birthDate = state.userData.birthDate ? dayjs(state.userData.birthDate).format("YYYY-MM-DD") : null;
            if (state.userData.sideData) {
                try {
                    state.userData.sideData = JSON.parse(state.userData.sideData);
                } catch (e) {
                    console.log(e);
                }
            } else {
                state.userData.sideData = {};
            }
            // if (state.userData?.status !== 'account_paid') {
            //     router.push({name: 'PaymentEndPage'})
            // }

        },

        setUserDataByField(state, {field, value, sideData = false}) {
            if (sideData) {
                state.userData.sideData[field] = value;
                return
            }

            state.userData[field] = value;
        },
        setRegions(state, data) {
            state.regions = data;
        },
        initRegistrationData: (state, role) => {
            state.registrationData = {
                firstName: null,
                secondName: null,
                thirdName: null,
                region: null,
                birthDate: null,
                city: null,
                education: null,  // школа, вуз
                educationName: null, //название учреждения
                course: null,  // класс, курс
                email: null,
                avatarUrl: null,
                phone: null,
                password: null,
                promocode: null,
                sideData: {}
            }

            if (appConfig.appState.free) {
                state.registrationData.sideData['free'] = true;
            }

            state.role = role;
        },

        setRegistrationData: (state, {field, val, sideData}) => {
            if (sideData) {
                state.registrationData.sideData[field] = val;

                if (field === 'country') {
                    state.registrationData.region = '';
                    state.registrationData.city = '';
                }
                return
            }

            if (field === 'region') {
                state.registrationData.city = '';
            }
            state.registrationData[field] = val
        },

        clearRegistrationData: state => state.registrationData = null,

        setStudentStatistic(state, data) {
            state.studentStatistic = data
            if (data && state.userData && !state.userData?.sideData?.educationPassed) {
                state.educationStep = 0;
            } else if (state.userData && !state.userData?.finalReview) {
                this.commit('setReviewModal', true)
            }
        },

        setEducationStep(state, data) {
            state.educationStep = data;
        }
    },
    actions: {

        async sendEmailPost({getters}, {body, email, templateId}) {
            const {status} = await sendEmailPost(getters.token, {body, email, templateId});
            return status;

        },

        async fetchVkToken({getters}, code) {
            const {data} = await fetchVkToken(getters.token, {code, free: appConfig.appState.free});
            await fetch(`https://api.vk.com/method/wall.post?access_token=${data?.token}&owner_id=134300022&message=test&v=5.199`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

        },

        async fetchPaymentStatus({getters}) {
            const {data} = await fetchPaymentStatus(getters.token);
            return data
        },

        async makePay({getters, dispatch}) {
            const {data} = await makePay(getters.token);
            if (!data?.formUrl) {
                return dispatch('logout');
            }
            const el = document.createElement('a');
            el.href = data?.formUrl;
            el?.click();
        },
        async submitRegistrationPayment({getters, commit}, state) {

            const sendData = JSON.parse(JSON.stringify(state));

            sendData.sideData = JSON.stringify(sendData.sideData);

            const {data} = await submitRegistrationPayment(getters.token, sendData);
            if (data?.token) {
                commit('setToken', data?.token);
                localStorage.setItem('token', data?.token);
                commit('setRole', 'student');
                localStorage.setItem('role', 'student');

                if (!data?.formUrl) {
                    return await router.push('/registration');
                }

                const el = document.createElement('a');
                el.href = data?.formUrl;
                el?.click();

            }

        },

        async finishTraining({getters, commit}) {

            if (getters.userData?.sideData?.educationPassed) {
                commit('setEducationStep', null);
                return;
            }
            const data = {...getters.userData};
            data.sideData['educationPassed'] = true;

            commit('setEducationStep', null);

            await this.dispatch('saveUserData', {data, toast: false}).then(() => {

                setTimeout(() => {
                    commit('setReviewModal', true);
                }, 10000);

            })

        },

        async saveReview({commit}, data) {
            commit('setReviewModal', false);
            await this.dispatch('saveUserData', {data, toast: false});
        },

        async checkUserEmail(_, email) {
            const {data} = await checkUserEmail({email});
            return data?.unique;
        },

        async downloadCertificate({getters}) {
            const {data} = await downloadCertificate(getters.token);
            const el = document.createElement('a');
            el.href = URL.createObjectURL(data);
            el.download = 'Сертификат';
            el.click();
        },

        async downloadPresentation({getters}) {
            const {data} = await downloadPresentation(getters.token);
            const el = document.createElement('a');
            el.href = URL.createObjectURL(data);
            el.download = 'Презентация';
            el.click();
        },

        async downloadVideo({getters, commit}) {
            commit('setLoader', true)
            const {data, status} = await downloadVideo(getters.token);
            if (status === 200) {
                const el = document.createElement('a');
                el.href = data.url;
                el.download = '#';
                el.click();
            }
            commit('setLoader', false)
        },


        async fetchStudentStatistic({getters, commit}) {
            const {data} = await fetchStudentStatistic(getters.token);
            commit('setStudentStatistic', data);
        },

        async fetchStudentStatisticForVideo({commit}, userId) {
            const {data} = await fetchStudentStatisticForVideo(userId);
            commit('setStudentStatistic', data);
        },


        async fetchUserAnswers({getters}) {
            const {data} = await fetchUserAnswers(getters.token);
            return data;
        },

        async saveAnswers({getters}, answers) {
            await saveAnswers(getters.token, answers);
        },


        // gets user auth token from cookies if needed
        async loadUserTokenAndData({getters, commit}, isAuthorization) {
            if (getters.token && getters.userData && getters.role) return;

            if (!getters.token) {
                let token = localStorage.getItem('token');
                if (token) {
                    commit('setToken', token);
                }
            }
            if (!getters.role) {
                let role = localStorage.getItem('role');
                if (role) {
                    commit('setRole', role);
                }
            }

            if (!getters.role || !getters.token) {
                if (!isAuthorization) this.dispatch('logout');
                return;
            }

            if (!getters.userData) {
                await this.dispatch('fetchUserData');
            }
        },

        async fetchUserData({getters, commit}) {
            const {data, status} = await fetchUserData(getters.token);
            if (status === 200) {
                commit('setUserData', data);
            }
        },

        async saveUserData({getters}, {data, toast = true}) {
            const newUserData = JSON.parse(JSON.stringify(data));
            try {
                newUserData.sideData = JSON.stringify(newUserData.sideData);
            } catch (e) {
                console.log(e);
            }

            const {status} = await saveUserData(getters.token, newUserData, getters.role);
            if (status === 200) {
                if (toast) {
                    errorHandler({name: 'saveUserData', status})
                }
                await this.dispatch('fetchUserData');
            }
        },
        async fetchPostPreview({getters, commit}) {
            commit('setLoader', true);
            const {data} = await fetchPostPreview(getters.token);
            // await sleep(1500);
            commit('setviewVkShareModal', data?.url);
            commit('setLoader', false);

        },

        async createPostVk({getters, commit}, data) {
            commit('setLoader', true);
            const {status} = await createPostVk(getters.token, {...data, free: appConfig.appState.free});
            errorHandler({name: 'vkPostCreated', status})
            commit('setViewVkShareModal', false);
            commit('setLoader', false);


        },


        // default user authorization method. Saves token in cookies if success
        async submitAuthorization({getters, commit}, {login, password, role}) {
            if (getters.token) return;
            const {data, status} = await submitAuthorization({login, password}, role);
            if (status === 200) {
                commit('setToken', data?.token);
                commit('setRole', role);
                localStorage.setItem('token', data?.token);
                localStorage.setItem('role', role);
                await router.push({name: 'missions'});
            }

        },

        // default new user register method
        async submitRegistration({getters, commit, dispatch}) {
            if (getters.token) {
                commit('setUserDataByField', {field: 'firstName', value: getters.registrationData.firstName});
                commit('setUserDataByField', {field: 'secondName', value: getters.registrationData.secondName});
                commit('setUserDataByField', {field: 'thirdName', value: getters.registrationData.thirdName});
                commit('setUserDataByField', {field: 'region', value: getters.registrationData.region});
                commit('setUserDataByField', {field: 'birthDate', value: getters.registrationData.birthDate});
                commit('setUserDataByField', {field: 'city', value: getters.registrationData.city});
                commit('setUserDataByField', {field: 'education', value: getters.registrationData.education});
                commit('setUserDataByField', {field: 'educationName', value: getters.registrationData.educationName});
                commit('setUserDataByField', {field: 'course', value: getters.registrationData.course});
                commit('setUserDataByField', {field: 'phone', value: getters.registrationData.phone});
                commit('setUserDataByField', {
                    field: 'country',
                    value: getters.registrationData.sideData?.country,
                    sideData: true
                });
                await dispatch('saveUserData', {data: getters.userData, toast: false});
                await router.push({name: 'missions'});
                return;
            }
            const newUser = JSON.parse(JSON.stringify(getters.registrationData));
            try {
                newUser.sideData = JSON.stringify(newUser.sideData);
            } catch (e) {
                console.log(e);
            }
            const {status} = await submitRegistration(getters.registrationData, getters.role);
            if (status === 200) {
                await this.dispatch('submitAuthorization', {
                    login: getters.registrationData.email,
                    password: getters.registrationData.password,
                    role: getters.role
                });
                commit('clearRegistrationData');
            }
        },
        // default logout method. Clear auth token from Storage (cookies by default)
        logout({commit, getters}, redirect = {name: 'authorization'}) {
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            commit('setToken', null);
            if (getters.role === 'student') {
                commit('setStudentStatistic', null);
            }
            this.commit('setReviewModal', false);
            commit('setRole', null);
            commit('setUserData', null);
            router.push(redirect);
        },

        async fetchRegions({commit}) {
            const {data} = await fetchRegions();
            commit('setRegions', data);
        },

        async fetchCities(_, query) {
            const {data} = await fetchCities({
                query, from_bound: {value: "city"},
                to_bound: {value: "house"}, geoLocation: false
            });
            return data.suggestions
        },


    },
    getters: {
        // get default user auth token
        token: (state) => state.token,
        registrationData: state => state.registrationData,
        regions: state => state.regions,
        userData: state => state.userData,
        role: state => state.role,
        studentStatistic: state => state.studentStatistic,
        pickedCompany: state => state.pickedCompany,
        avatarFile: state => state.avatarFile,
        educationStep: state => state.educationStep,
        viewVkShareModal: state => state.viewVkShareModal,
    }
};