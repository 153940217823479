<template>
  <div class="filling-6">
    <img src="@/assets/imgs/video/bg-2-2.png" alt="" class="bg-6" />
    <div class="content-6">
      <p class="title-6">
        Мира, посмотри, какие роли <br />
        в команде тебе подходят больше остальных
      </p>
      <div class="description-6">
        <div class="roles-6">
          <div class="roles-element-6">
            <img
              src="@/assets/imgs/video/generator.webp"
              alt=""
              class="roles-img-6"
            />
            <p class="roles-title-6">Да ты настоящий генератор идей!</p>
            <p class="roles-text-6">
              Однозначно, сделанные на базе интернет-аналитики выводы, которые
              представляют собой яркий пример континентально-европейского типа
              политической культуры, будут ассоциативно распределены по
              отраслям.
            </p>
          </div>
          <div class="roles-element-6">
            <img
              src="@/assets/imgs/video/lider.webp"
              alt=""
              class="roles-img-6"
            />
            <p class="roles-title-6">Да ты настоящий генератор идей!</p>
            <p class="roles-text-6">
              Однозначно, сделанные на базе интернет-аналитики выводы, которые
              представляют собой яркий пример континентально-европейского типа
              политической культуры, будут ассоциативно распределены по
              отраслям.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentationElement6",
};
</script>

<style scoped>
.filling-6 {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  height: 100vh;
  height: 100dvh;
}

.description-6 {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.bg-6 {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content-6 {
  max-width: 100%;
  width: 100%;
  padding: 100px;
  position: relative;
  height: 100%;
}

.title-6 {
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  line-height: 143.5%;
  letter-spacing: 3.3px;
  text-transform: uppercase;
  text-align: left;
}

.roles-6 {
  display: flex;
  justify-content: center;
  gap: 200px;
}

.roles-element-6 {
  max-width: 425px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.roles-img-6 {
  width: 186px;
}

.roles-title-6 {
  margin-top: 23px;
  font-size: 33px;
  font-weight: 700;
  line-height: 143.5%;
  letter-spacing: 1.98px;
}

.roles-text-6 {
  margin-top: 27px;
  font-size: 26.855px;
  line-height: normal;
}
</style>
