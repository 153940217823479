<template>
  <div class="content">
    <page-header></page-header>
    <div class="mission-wrapper container">
      <div class="mission-content">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/general/PageHeader";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { provide } from "vue";

export default {
  name: "MissionRouter",
  components: {
    PageHeader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    provide("store", store);
    provide("router", router);
  },
};
</script>

<style scoped>
.mission-content {
  position: relative;
  overflow: auto;
}

@media (max-width: 850px) {
  .mission-content {
    padding-top: 2rem;
    min-height: calc(100vh - 4rem);
    height: 100%;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .mission-wrapper {
    position: relative;
  }

  .chat__logo {
    width: 5.75rem;
    height: 3.9375rem;
    object-fit: contain;
    margin: 0;
  }
}
</style>
