<template>
  <div
      class="wrapper"
      :class="{ 'registration-wrapper': $route.name == 'registration' }"
  >
    <PageHeader></PageHeader>
    <div class="filling container">
      <page-menu v-if="$route.name !== 'registration'" :education-step="educationStep"></page-menu>
      <div
          class="content"
          :class="{ 'chat-content': $route.name == 'registration' }"
      >
        <router-view :key="$route.fullPath"></router-view>
        <page-footer v-if="$route.name !== 'registration'"></page-footer>
      </div>
    </div>
    <payment-score-element v-if="viewPaymentByScoreModal&&!userData?.sideData?.scoresPaymentTarget"
                           @savePaymentData="savePaymentData"></payment-score-element>
    <payment-money-element v-if="viewPaymentByMoneyModal"></payment-money-element>
    <vk-share-modal v-if="viewVkShareModal"></vk-share-modal>
    <loader-element v-if="viewLoader"></loader-element>
    <training-modal v-if="educationStep!=null" :education-step="educationStep"
                    :status="userData?.status"></training-modal>
    <review-modal v-if="viewReviewModal"></review-modal>
    <congratulation-modal v-if="viewPaymentByScoreModal&&userData?.sideData?.scoresPaymentTarget"
                          :scores-payment-target="userData?.sideData?.scoresPaymentTarget"
                          @close="closeScoresResults"></congratulation-modal>
  </div>
</template>

<script>
import PageHeader from "@/components/general/PageHeader.vue";
import PageFooter from "@/components/general/PageFooter.vue";
import PageMenu from "@/components/general/PageMenu.vue";
import PaymentScoreElement from "@/components/profile/PaymentScoreElement.vue";
import PaymentMoneyElement from "@/components/profile/PaymentMoneyElement.vue";
import LoaderElement from "@/components/general/LoaderElement.vue";
import TrainingModal from "@/components/general/TrainingModal.vue";
import ReviewModal from "@/components/profile/ReviewModal.vue";
import {useStore} from "vuex";
import {computed, provide, readonly} from "vue";
import {useRouter} from "vue-router";
import CongratulationModal from "@/components/profile/CongratulationModal.vue";
import {errorHandler} from "@/lib/errorHandler";
import VkShareModal from "@/components/profile/VkShareModal.vue";

export default {
  name: "PageWrapper",
  components: {
    VkShareModal,
    CongratulationModal,
    PageHeader,
    PageFooter,
    PageMenu,
    PaymentScoreElement,
    PaymentMoneyElement,
    LoaderElement,
    TrainingModal,
    ReviewModal,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    provide("store", readonly(store));
    provide("router", readonly(router));
    const educationStep = computed(() => store.getters.educationStep);
    const userData = computed(() => store.getters.userData);

    const viewVkShareModal = computed(() => store.getters.viewVkShareModal)

    const viewPaymentByScoreModal = computed(() => store.getters.viewPaymentByScoreModal);


    const savePaymentData = async ({target, phone, email, name}) => {

      const data = JSON.parse(JSON.stringify(userData.value));

      let templateId;

      if (target === 'courses') {
        templateId = '3057acba-d613-11ee-8481-9e9405934f1d';
      }

      if (target === 'marathon') {
        templateId = '4bc2af10-d61b-11ee-a897-22db587ecd3f'
      }

      if (templateId) {
        const status = await store.dispatch('sendEmailPost', {body: {}, email, templateId});
        if (status === 200) {

          data.sideData['scoresPaymentTarget'] = target;
          data.sideData['scoresPaymentUserData'] = {
            name, phone, email
          };


          await store.dispatch('saveUserData', {data, toast: false});
        } else {
          errorHandler({name: 'emailError'});
        }
      }


    }


    return {
      viewPaymentByMoneyModal: computed(() => store.getters.viewPaymentByMoneyModal),
      viewPaymentByScoreModal,
      viewLoader: computed(() => store.getters.viewLoader),
      viewReviewModal: computed(() => store.getters.viewReviewModal),
      userData,
      educationStep,
      savePaymentData,
      closeScoresResults: () => {
        store.commit('setPaymentByScoreModal', false);
      },
      viewVkShareModal
    };
  },
};
</script>

<style scoped>
.filling {
  display: flex;
  align-items: flex-start;
  padding: 0 1.69rem;
}

.content {
  max-width: 100%;
  width: 100%;
  padding: 3rem 0 0 3rem;
}

.chat-content {
  padding: 0;
}

.registration-wrapper {
  background: #1e2229;
}

@media (max-width: 1600px) {
  .content {
    padding: 3.13rem 0 0 3rem;
  }
}

@media (max-width: 850px) {
  .filling {
    margin-top: 2.5rem;
    flex-direction: column;
    padding: 0 1rem;
    overflow-x: hidden;
  }

  .content {
    margin-top: 1.5rem;
    padding: 0;
  }
}
</style>
