// makes post request to server
export async function makePostReq(url, data, params) {
    const requestOptions = {
        method: "POST",
        body: data
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
        if (params.data) {
            requestOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
            requestOptions.body = JSON.stringify(data);
        }
    }

    const req = await fetch(url, requestOptions);
    let reqData;
    try {
        reqData = await req.json();

    } catch (e) {
        reqData = null;
    }
    return {
        data: reqData,
        status: req.status
    }
}

export async function makePutReq(url, data, params) {
    const requestOptions = {
        method: "PATCH",
        body: data
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
        if (params.data) {
            requestOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
            requestOptions.body = JSON.stringify(data);
        }
    }

    const req = await fetch(url, requestOptions);
    const reqData = await req.json();
    return {
        data: reqData,
        status: req.status
    }
}

export async function makeDeleteReq(url, data, params) {
    const requestOptions = {
        method: "DELETE",
        body: data
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
        if (params.data) {
            requestOptions.headers['Content-Type'] = 'application/json;charset=UTF-8';
            requestOptions.body = JSON.stringify(data);
        }
    }

    const req = await fetch(url, requestOptions);
    const reqData = await req.json();
    return {
        data: reqData,
        status: req.status
    }
}

export async function makeGetReq(url, params) {
    const requestOptions = {
        method: "GET"
    };
    if (params) {
        requestOptions['headers'] = {};
        if (params.token) {
            requestOptions.headers['X-Auth-Token'] = params.token;
        }
    }
    const req = await fetch(url, requestOptions);
    const reqData = params?.blob ? await req.blob() : await req.json();
    return {
        data: reqData,
        status: req.status
    }
}


export async function makeCorsPostReq(url, params) {

    const requestOptions = {
        method: "POST",
        mode: 'cors',
        headers: params.headers,
        body: JSON.stringify(params.data)
    };
    const req = await fetch(url, requestOptions);
    const reqData = await req.json();
    return {
        data: reqData,
        status: req.status
    }
}