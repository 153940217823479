import {uploadFile} from "@/store/application/serverInterationMethods";


export const application = {
    state: {
        viewPaymentByScoreModal: false,
        viewPaymentByMoneyModal: false,
        viewReviewModal: false,
        viewLoader: false
    },
    mutations: {
        setLoader(state, data) {
            state.viewLoader = data;
        },
        setPaymentByScoreModal(state, data) {
            state.viewPaymentByScoreModal = data;
        },
        setPaymentByMoneyModal(state, data) {
            state.viewPaymentByMoneyModal = data;
        },
        setReviewModal(state, data) {
            state.viewReviewModal = data;
        },
    },
    actions: {
        async uploadFile(_, file) {
            const formData = await new FormData();
            formData.append('file', file);
            const {data} = await uploadFile(formData);
            return data.url;
        },

    },
    getters: {
        viewPaymentByScoreModal: (state) => state.viewPaymentByScoreModal,
        viewPaymentByMoneyModal: (state) => state.viewPaymentByMoneyModal,
        viewReviewModal: (state) => state.viewReviewModal,
        viewLoader: state => state.viewLoader,
    }
};