<template>
  <div class="review-wrapper">
    <form class="review" id="review" @submit.prevent>
      <button type="button" class="close" @click="saveReview">
        <img src="@/assets/imgs/close.svg" alt="" class="close__img" />
      </button>
      <img src="@/assets/imgs/robot-face.png" alt="" class="robot">

      <p class="message ">
        <span class="purple-text">
          И это ещё не всё<span class="smile">🤗</span>! <br>
          Оказывается, твои способности на {{ randNumber }}% выше, чем у многих взрослых. И мне очень понравилось с
          тобой общаться<span class="smile">😉</span> <br>
          А теперь давай оценим всё, что ты увидел и узнал по 5-балльной шкале, где 1 — совсем не понравилось, а 5 —
          было очень интересно и полезно!
        </span>
      </p>
      <div class="radio-number-wrapper">
        <label class="radio-number" v-for="item in 5" :key="item">
          <input type="radio" name="number" :value="item" v-model="finalScore" class="radio-number__checkbox" />
          <span class="radio-number__content">
            <span class="radio-number__content-text">{{ item }}</span>
          </span>
        </label>
      </div>
      <p class="message purple-text"><span class="purple-text">Оставь свой отзыв<span class="smile">📝</span>, чтобы я
          смог стать ещё лучше для тебя.</span>
      </p>
      <textarea placeholder="Отзыв" class="textarea" v-model="finalReview"></textarea>
      <button type="button" class="send " @click="saveReview">Спасибо! Нажимай, и продолжим</button>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { errorHandler } from "@/lib/errorHandler";

export default {
  name: "ReviewModal",
  setup() {
    const store = useStore();

    const finalReview = ref('');
    const finalScore = ref('');
    const randNumber = Math.floor(70 + Math.random() * (90 + 1 - 70));


    const saveReview = () => {
      if (!finalReview.value?.trim() || !finalScore.value) {
        errorHandler({ name: 'emptyReview' });
        return;
      }


      const userData = JSON.parse(JSON.stringify(store.getters.userData));
      userData['finalReview'] = (finalReview.value ? finalReview.value : '-');
      userData['finalScore'] = `${finalScore.value}`;

      store.dispatch('saveReview', userData);
    }

    return {
      finalReview,
      finalScore,

      saveReview,
      randNumber
    }
  }

};
</script>

<style scoped>
.review-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  z-index: 13;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  overflow: auto;
  padding: 3rem;
  display: flex;
}

.review {
  padding: 1.875rem;
  border-radius: 2rem;
  background: #101215;
  position: relative;
  pointer-events: all;
  max-width: 47.9375rem;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.close {
  position: absolute;
  right: -3rem;
  top: 0;
  padding: 0.62rem;
  cursor: pointer;
}

.close__img {
  width: 1.75rem;
  height: 1.75rem;
}

.robot {
  width: 4.09725rem;
}

.message {
  max-width: 69.25rem;
  background: #fff;
  width: 100%;
  font-weight: 500;
  font-size: 1.25rem;
  padding: 1.25rem 1.875rem;
  border-radius: 0.625rem;
  word-wrap: break-word;
  line-height: 1;
}

.smile {
  -webkit-text-fill-color: saddlebrown !important;
}

.textarea {
  padding: 1.5rem;
  border-radius: 0.625rem;
  background: #4C4C4C;
  min-height: 6.6875rem;
  resize: vertical;
  max-width: 100%;
  width: 100%;
  border: none;
  color: #fff;
}

.radio-number-wrapper {
  margin: 0;
  justify-content: flex-start;
}

.send {
  display: flex;
  color: #FFF;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: normal;
  padding: 0.87rem;
  max-width: 15.6rem;
  width: 100%;
  text-align: center;
  border-radius: 0.625rem;
  background: linear-gradient(92deg, #AD4FEA 4.41%, #4A58DD 72.27%);
}

@media (max-width: 850px) {
  .review-wrapper {
    padding: 3rem 1rem;
    background: rgba(0, 0, 0, 0.66);
  }

  .review {
    padding: 1.5rem;
    border-radius: 0.625rem;
    max-width: 27rem;
  }

  .close {
    right: 0;
    top: -3rem;
  }

  .message {
    font-size: 1.125rem;
    line-height: 1;
    padding: 0.75rem 1rem;
  }

  .textarea {
    padding: 0.75rem;
  }

}
</style>
