<template>
  <div class="filling-1">
    <img src="@/assets/imgs/video/bg-1-1.webp" alt="" class="bg" />
    <div class="content-1">
      <p class="title-1">
        Мира, твои результаты использования цифрового помощника для выбора
        профессии
      </p>
      <div class="helper-1">
        <img
          src="@/assets/imgs/video/robot-small.webp"
          alt=""
          class="robot-1"
        />
        <p class="text-1">Цифровой помощник</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentationElement1",
};
</script>

<style scoped>
.filling-1 {
  display: flex;
  align-items: center;
  width: 1920px;
  position: relative;
  height: 1080px;
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content-1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}

.title-1 {
  max-width: 1104px;
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  line-height: 143.5%;
  letter-spacing: 3.3px;
  text-transform: uppercase;
}

.helper-1 {
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 14px;
}

.robot-1 {
  width: 58px;
}

.text-1 {
  font-size: 19.333px;
}
</style>
