<template>
  <div class="professions-element">
    <div class="caption">
      <img :src="require(`@/assets/imgs/test-data/icon${index}.svg`)" alt="" class="professions-element__icon">
      <p class="professions-element__text">{{ profession.name }}</p>
    </div>
    <a
        target="_blank"
        :href="profession.url"
        class="professions-element__btn statistics__btn"
    >
      Узнать у профессионала
    </a>
  </div>
</template>

<script>
export default {
  name: "ProfessionsElement",
  props: ["profession",'index'],
};
</script>

<style scoped>
.statistics__btn {
  margin-top: 0.5rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  line-height: normal;
}

.professions-element {
  padding: 1.75rem 1.57rem 1.31rem 1.57rem;
  border-radius: 1rem;
  background: rgba(98, 98, 98, 0.17);
  backdrop-filter: blur(32.584266662597656px);
}
.caption{
  display: flex;
  align-items: center;
  gap: 0.87rem;
}

.professions-element__text {
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.professions-element__icon{
  display: flex;
  flex-shrink: 0;
  width: 3.5rem;
  height: 3.5rem;
  object-fit: contain;
}
.professions-element__btn {
  margin-top: 1.62rem;
  font-size: 1rem;
  padding: 0.7rem;
}

@media (max-width: 850px) {
  .professions-element {
    padding: 0.62rem !important;
    box-shadow: none;
    backdrop-filter: blur(32.584266662597656px);
    border-radius: 0.625rem;
    background: linear-gradient(182deg, #101215 0%, #21262e 120%);
  }

  .professions-element__text {
    font-size: 1.3rem;
    line-height: 140%;
  }

  .professions-element__btn {
    margin-top: 0.63rem;
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  .professions-element__icon{
    width: 2rem;
    height: 2rem;
  }
}
</style>
