<template>
  <div class="payment-wrapper">
    <div class="payment" id="payment">
      <button class="close" @click="closeUploadFile">
        <img src="@/assets/imgs/close.svg" alt="" class="close__img"/>
      </button>
      <cropper class="cropper" :src="photoUrl" :stencil-component="$options.components.CircleStencil" ref="cropper"
               :stencil-props="{ aspectRatio: 6 / 9 }" @change="change"/>
      <div class="btns">
        <button class="save" @click="uploadPhoto">Сохранить</button>
        <button class="cancel" @click="closeUploadFile">Отменить</button>
      </div>

    </div>
  </div>
  <circle-stencil v-if="false"></circle-stencil>
</template>

<script>

import {computed, inject, ref} from "vue";
import {useStore} from "vuex";
import {Cropper, CircleStencil} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';


export default {
  name: "ChangePhoto",
  components: {
    Cropper,
    CircleStencil
  },
  props: ['userData', 'avatarFile'],
  setup(props) {
    const store = inject('store') || useStore();

    const cropper = ref(null);

    const closeUploadFile = () => store.commit('setAvatarFile', null);
    const uploadPhoto = () => {
      const {canvas} = cropper.value?.getResult();

      if (canvas) {
        canvas.toBlob(blob => {
          const newUserData = JSON.parse(JSON.stringify(props.userData));
          store.dispatch("uploadFile", blob).then((url) => {
            newUserData.avatarUrl = url;
            store.dispatch("saveUserData", {data: newUserData}).then(closeUploadFile);
          });
        }, 'image/jpeg');
      }

    };


    return {
      uploadPhoto,
      closeUploadFile,
      photoUrl: computed(() => props.avatarFile ? URL.createObjectURL(props.avatarFile) : null),
      cropper
    }
  },
};
</script>

<style scoped>
.payment-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  max-width: 100%;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;

  background: rgba(0, 0, 0, 0.5);
}

.payment {
  padding: 1.875rem;
  border-radius: 2rem;
  background: #101215;
  position: relative;
  pointer-events: all;
  max-width: 37.938rem;
  pointer-events: all;
}

.photo {
  max-width: 100%;
  width: 100%;
  border-radius: 100%;
}

.cancel {
  max-width: 100%;
  width: 100%;
  border-radius: 0.625rem;
  background: rgba(255, 255, 255, 0.10);
  color: #fff;
  text-align: center;
  font-size: 1.25rem;
}

.close {
  position: absolute;
  right: -3rem;
  top: 0;
  padding: 0.62rem;
  cursor: pointer;
}

.close__img {
  width: 1.75rem;
  height: 1.75rem;
}

.btns {
  margin-top: 1rem;
  max-width: 100%;
  width: 100%;
  display: flex;
  gap: 1rem;
}

.save {
  max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  padding: 0.75rem;
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  line-height: normal;
}

@media (max-width: 850px) {
  .payment-wrapper {
    padding: 2rem 1rem;
    background: rgba(0, 0, 0, 0.66);
  }

  .payment {
    padding: 1rem;
    border-radius: 0.625rem;
    max-width: 25rem;
  }

  .close {
    right: 0;
    top: -3rem;
  }

  .save {
    font-size: 1rem;
  }

  .cancel {
    font-size: 1rem;
  }
}

.cropper {
  min-width: 25rem;
  max-width: 100%;
  max-height: 75vh;
  width: 100%;
  background: #DDD;
}
</style>
