<template>
  <div class="robot-message mission-container" :key="iterator">
    <div class="robot-message-text-wrapper" v-if="questions[iterator].body || questions[iterator].purpleBody">
      <pre v-if="questions[iterator].body">{{ questions[iterator].body }}</pre>
      <pre v-if="questions[iterator].purpleBody && !questions[iterator].smiles"
        class="purple-text">{{ questions[iterator].purpleBody }}</pre>
      <pre v-show="questions[iterator].purpleBody && questions[iterator].smiles" class="purple-text"><span
          :class="{ 'smile-el': smilesList?.includes(item) }"
          v-for="(item, index) in questions[iterator].purpleBody?.split('#')"
          :key="index">{{ item }}</span></pre>
      <pre class="last-text" v-if="(questions[iterator].body || questions[iterator].purpleBody) &&
        questions[iterator].type !== 'none' &&
        questions[iterator].type !== 'open'
      ">
Нужно выбрать {{
  questions[iterator].type === "checkbox" ? "2 варианта ответа" : "1 вариант ответа"
}} </pre>
    </div>
    <!--    <div class="chat-pop-up pop-up" v-if="false">-->
    <!--      <button class="select-btn chat-select-btn" @click="viewRegions = !viewRegions">-->
    <!--        Выбрать регион-->
    <!--        <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">-->
    <!--          <path-->
    <!--              d="M13 8.97205L3.62037 1.15566C2.87788 0.53692 1.77439 0.637236 1.15566 1.37972C0.536919 2.12221 0.637237 3.22569 1.37972 3.84443L11.8797 12.5944C12.5287 13.1352 13.4714 13.1352 14.1204 12.5944L24.6204 3.84443C25.3629 3.22569 25.4632 2.12221 24.8444 1.37972C24.2257 0.637236 23.1222 0.53692 22.3797 1.15566L13 8.97205Z"-->
    <!--              fill="#AD4FEA" fill-opacity="0.4"/>-->
    <!--        </svg>-->
    <!--      </button>-->
    <!--      <div class="select-list chat-select-list" v-if="viewRegions">-->
    <!--        <button class="select-element chat-select-element">Россия</button>-->
    <!--        <button class="select-element chat-select-element">Россия</button>-->
    <!--        <button class="select-element chat-select-element">Россия</button>-->
    <!--        <button class="select-element chat-select-element">Россия</button>-->
    <!--        <button class="select-element chat-select-element">Россия</button>-->
    <!--        <button class="select-element chat-select-element">Россия</button>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="radio-number-wrapper" v-if="questions[iterator].type === 'rating' ||
      questions[iterator].type === 'response'
    ">
      <label class="radio-number" v-for="item in questions[iterator].answers" :key="item.value">
        <input type="radio" name="number" @change="setAnswer(item.name)" :value="item.name"
          class="radio-number__checkbox" />
        <div class="radio-number__content">
          <p class="radio-number__content-text" v-if="questions[iterator].type === 'rating'">
            {{ item.name }}
          </p>
          <img class="radio-number__content-img" :src="item.value" v-if="questions[iterator].type === 'response'" />
        </div>
      </label>
    </div>
    <div class="radio-text-wrapper" v-if="questions[iterator].type === 'radio' ||
      questions[iterator].type === 'checkbox' ||
      questions[iterator].type === 'company'
    ">
      <label class="radio-text" v-for="item in questions[iterator].answers" :key="item.value">
        <input v-if="questions[iterator].type === 'company'" type="radio" v-model="singleAnswer" :value="item.value"
          name="text" class="radio-text__checkbox" />

        <input v-else :type="questions[iterator].type" :checked="missionPassing.answers[questions[iterator].id]?.includes(item.value)
          " @change="setAnswer(item.value)" :value="item.value" name="text" class="radio-text__checkbox" />

        <div class="radio-text__content">
          <div class="radio-text__round"></div>
          <p class="radio-text__content-text">
            {{ item.name }}
          </p>
        </div>
      </label>
    </div>
    <div class="radio-text-wrapper radio-images" v-if="questions[iterator].type === 'img'">
      <label class="radio-text" v-for="item in questions[iterator].answers" :key="item.name">
        <div class="radio-images-caption">
          <input type="radio" name="text" v-model="singleAnswer"
            :value="item?.name[0]?.toUpperCase() + item?.name?.slice(1)" class="radio-text__checkbox" />
          <div class="radio-text__content">
            <div class="radio-text__round"></div>
            <p class="radio-text__content-text">
              {{ item.name }}
            </p>
          </div>
        </div>
        <img :src="item.value" alt="" class="radio-images__img" />
      </label>
    </div>
    <div class="radio-text-wrapper radio-text-yes-no" v-if="questions[iterator].type === 'yesOrNo'">
      <label class="radio-text">
        <input type="radio" name="text-yes-no" @change="setAnswer('a')" class="radio-text__checkbox" />
        <div class="radio-text__content">
          <div class="radio-text__round"></div>
          <p class="radio-text__content-text">да</p>
        </div>
      </label>
      <label class="radio-text">
        <input type="radio" name="text-yes-no" @change="setAnswer('b')" class="radio-text__checkbox" />
        <div class="radio-text__content">
          <div class="radio-text__round"></div>
          <p class="radio-text__content-text">нет</p>
        </div>
      </label>
    </div>
    <textarea v-if="questions[iterator].type === 'open'" @change="setAnswer($event.target.value)" class="textarea"
      placeholder="Отзыв"></textarea>
    <div class="robot-message__btn-wrapper">
      <button class="robot-message__btn button-purple" :class="{ 'disabled-btn': !accessNextQuestion }"
        @click="nextQuestion">
        {{ questions[iterator].continueText }}
      </button>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref, watch } from "vue";

export default {
  name: "MissionMessage",
  props: ["questions", "missionPassing"],
  setup(props, { emit }) {
    const store = inject("store");
    const iterator = ref(0);
    const multiAnswers = ref([]);
    const singleAnswer = ref(null);
    const skippingQuestion = ref(false);
    const oldIterator = ref(null);
    const clickBlocked = ref(false);

    const setAnswer = (val) => {
      store.commit("setMissionPassingAnswer", {
        val,
        type: props.questions[
          oldIterator.value != null ? oldIterator.value : iterator.value
        ].type,
        id: props.questions[
          oldIterator.value != null ? oldIterator.value : iterator.value
        ].id,
        skipQuestion: () => (skippingQuestion.value = true),
      });

      if (oldIterator.value != null) {
        oldIterator.value = null;
      }
    };

    watch(
      () => iterator.value,
      (val, oldValue) => {
        if (
          props.questions[oldValue].type === "img" ||
          props.questions[oldValue].type === "company"
        ) {
          multiAnswers.value.push(singleAnswer.value);
          oldIterator.value = oldValue;
        }
      }
    );

    watch(
      () => multiAnswers.value.length,
      (val) => {
        if (
          (val === 4 && props.missionPassing.missionCount === 1) ||
          (val === 3 &&
            (props.missionPassing.missionCount === 4 ||
              props.missionPassing.missionCount === 5))
        ) {
          setAnswer(multiAnswers.value.join(","));
          multiAnswers.value = [];
        }

        singleAnswer.value = null;
      }
    );

    const accessNextQuestion = computed(() => {
      if (
        (props.questions[iterator.value].type === "yesOrNo" ||
          props.questions[iterator.value].type === "radio" ||
          props.questions[iterator.value].type === "response" ||
          props.questions[iterator.value].type === "rating") &&
        Object.keys(props.missionPassing.answers).includes(
          props.questions[iterator.value].id
        )
      )
        return true;

      if (
        (props.questions[iterator.value].type === "img" ||
          props.questions[iterator.value].type === "company") &&
        singleAnswer.value
      )
        return true;

      if (
        props.questions[iterator.value].type === "checkbox" &&
        props.missionPassing.answers[props.questions[iterator.value].id]?.split(
          ","
        ).length === 2
      )
        return true;

      if (
        props.questions[iterator.value].type === "none" ||
        props.questions[iterator.value].type === "open"
      )
        return true;

      return false;
    });
    return {
      multiAnswers,
      singleAnswer,
      iterator,
      accessNextQuestion,
      setAnswer,

      smilesList: computed(() => store.getters.smilesList),

      nextQuestion: () => {
        if (clickBlocked.value) return;
        if (iterator.value !== props.questions.length - 1) {
          iterator.value++;
          if (skippingQuestion.value) {
            iterator.value++;
            skippingQuestion.value = false;
          }
        } else {
          clickBlocked.value = true;
          emit("finishMission");
        }
      },
    };
  },
  data() {
    return {
      viewRegions: false,
    };
  },
};
</script>

<style scoped>
.smile-el {
  -webkit-text-fill-color: black;
}

.mission-container {
  max-width: 69.25rem;
  width: 100%;
}

.robot-message-text-wrapper {
  margin-top: 0.5rem;

  font-weight: 500;
  font-size: 0.938rem;
  color: rgba(19, 19, 19, 0.8);
  padding: 1.25rem 1.875rem;
  background: #fff;
  border-radius: 10px;
  word-wrap: break-word;
  line-height: 115%;
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
}

.last-text {
  color: #9b9b9b;
}

.robot-message__btn {
  margin-top: 2.81rem;
  font-size: 1.25rem;
  margin-left: auto;
  transition: all 1s ease-in-out;
}

.chat-pop-up {
  max-width: 69.25rem;
  font-weight: 400;
  font-size: 0.938rem;
  line-height: 1;
  color: #000000;
  margin-top: 1rem;
}

.chat-select-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.125rem 1.5rem 1.125rem 1.875rem;
  border-radius: 0.625rem;
  background: #fff;
}

.chat-select-element {
  background: #fff;
  padding: 0.875rem 1.5rem 0.875rem 1.875rem;
  border-bottom: 1px solid #1e2229;
  text-align: left;
}

.chat-select-element:first-child {
  border-radius: 0.625 0.625 0 0;
}

.chat-select-element:last-child {
  border-radius: 0 0 0.625 0.625;
  border: none;
}

.radio-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.radi {
  border-radius: 0.625rem 0.625rem 0 0;
  padding: 2.34rem 2.18rem;
  background: none;
  border: 1px solid #fff;
  border-bottom: none;
}

.radio-images__img {
  max-width: 100%;
  width: 100%;
  border-radius: 0 0 0.625rem 0.625rem;
  border: none;
}

.textarea {
  margin-top: 2.25rem;
  color: rgba(255, 255, 255, 0.7);
  padding: 1.25rem 1.87rem;
  border-radius: 0.625rem;
  background: #4c4c4c;
  font-size: 1.125rem;
  line-height: 115%;
  max-width: 100%;
  width: 100%;
  border: none;
  display: flex;
}

.robot-message__btn-wrapper {

  padding-bottom: 2em;
  display: flex;
}

.radio-images-caption .radio-text__content {
  border-radius: 0.625rem 0.625rem 0 0;
}

pre {
  font-size: 1.5rem;
}

@media (max-width: 850px) {
  pre {
    font-size: 1.125rem;
  }

  .mission-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .robot-message-text-wrapper {
    max-width: 100%;
    font-size: 1.125rem;
    line-height: 115%;
    padding: 0.75rem 1rem;
    gap: 0.62rem;
  }

  .robot-message__btn {
    margin-top: 1rem;
    font-size: 1.125rem;
  }

  .chat-pop-up {
    max-width: 100%;
    font-weight: 400;
    font-size: 0.75rem;
  }

  .chat-select-btn {
    padding: 0.75rem 0.813rem 0.75rem 1rem;
  }

  .chat-select-btn svg {
    width: 0.875rem;
  }

  .chat-select-element {
    padding: 0.5rem 0.813rem 0.5rem 1rem;
  }

  .robot-message__btn-wrapper {
    margin-top: 2rem;
    max-width: 100%;
    width: 100%;
    padding: 0 1px 1rem 1px;
  }

  .robot-message__btn {
    margin-top: 1rem;
    margin-top: 0;
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .radio-images {
    margin-top: 0.75rem;
    gap: 0.25rem;
  }

  .radi {
    padding: 0.5rem;
  }

  .textarea {
    margin-top: 0.75rem;
    padding: 1rem 0.56rem;

    min-height: 9.25rem;
  }
}

.disabled-btn {
  background: #121418;
  color: #fff;
  pointer-events: none;
  border: none;
  z-index: 2;
  position: relative;
  overflow: visible;
}

.disabled-btn::before {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border-radius: inherit;
  background: linear-gradient(151deg, #ad4fea 0%, #4a58dd 100%);
  z-index: -1;
}

.disabled-btn::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  background: #121418;
  z-index: -1;
}
</style>
