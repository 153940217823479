export const appConfig = {
    // domain: 'https://app.profstories.ru:8086/api/v1',
    // domain: 'http://172.20.10.13:3000/api/v1',
    domain: 'https://цифровойпомощник.рф:8086/api/v1',
    appState: {
        free: true
    },
    vkClientId: 51866978,
    getVkRedirectUrl() {
        return this.appState.free ? 'https://free8734.xn--b1albdgkhebbbji9c2a3d.xn--p1ai/cabinet/statistics' : 'https://xn--b1albdgkhebbbji9c2a3d.xn--p1ai/cabinet/statistics';
    }
}