<template>
  <div class="event">
    <div class="event-caption">
      <p class="event__status">{{ calendarEvent.format }}</p>
      <p class="event__date">{{ calendarEvent.event_date }}</p>
    </div>
    <div class="event-filling">
      <p class="event__name">{{ calendarEvent.title }}</p>

      <!--      <label class="switch">-->
      <!--        <input type="checkbox" checked />-->
      <!--        <span class="slider round"></span>-->
      <!--      </label>-->
    </div>
    <div class="events-activation">
      <button class="event__btn button-purple" @click="deleteCalendarEvent">
        Скрыть
      </button>
      <a @click="sendEventClickInfo"
         :href="calendarEvent.event_link"
         target="_blank"
         class="event__btn button-purple"
      >Принять участие</a
      >
    </div>
  </div>
</template>

<script>
import {inject, shallowRef} from "vue";

export default {
  name: "CalendarEvent",
  props: ["calendarEvent"],
  setup(props) {
    const {dispatch} = inject("store");

    const isInfoSent = shallowRef(false);

    async function sendEventClickInfo() {
      if (isInfoSent.value) return;
      isInfoSent.value = true;
      await dispatch('sendEventClickInfo', props.calendarEvent?.id);
    }

    return {
      deleteCalendarEvent: () =>
          dispatch("deleteCalendarEvent", props.calendarEvent.id),

      sendEventClickInfo
    };
  },
};
</script>

<style scoped>
.event {
  padding: 1.25rem;
  border-radius: 1rem;
  background: #b2d8ff;
  display: flex;
  flex-direction: column;
}

.event-caption {
  display: flex;
  align-items: center;
  gap: 2.56rem;
}

.event__status {
  border-radius: 1rem;
  border: 2px solid #fff;
  color: #fff;
  max-width: 10rem;
  width: 100%;
  padding: 0.31rem 0.8rem;
  text-align: center;
}

.event__date {
  color: #000;
  font-size: 1.0205rem;
  font-weight: 400;
}

.event-filling {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.event__name {
  font-size: 1.3rem;
  font-style: normal;
  color: #000;
  line-height: 140%;
}

.event__btn {
  margin-left: auto;
  font-size: 1.42869rem;
  font-style: normal;
  font-weight: 700;
  padding: 0.92rem 1.97rem;
  margin: 0;
}

.events-activation {
  display: flex;
  gap: 1rem;
  margin-top: 1.56rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 4.26794rem;
  height: 2.125rem;
  display: flex;
  flex-shrink: 0;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a9a9a9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 60px;
  display: flex;
  flex-shrink: 0;
}

.slider:before {
  position: absolute;
  content: "";
  width: 2.125rem;
  height: 2.125rem;
  left: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 100%;
}

input:checked + .slider {
  background-color: #826ff2;
}

input:checked + .slider:before {
  -webkit-transform: translateX(2.13397rem);
  -ms-transform: translateX(2.13397rem);
  transform: translateX(2.13397rem);
}

@media (max-width: 850px) {
  .event {
    padding: 1.06rem;
    border-radius: 0.625rem;
  }

  .event-filling {
    margin-top: 0.94rem;
  }

  .event__name {
    font-size: 1rem;
  }

  .event-caption {
    gap: 1rem;
  }

  .event__status {
    font-size: 0.625rem;
    line-height: normal;
    padding: 0.2rem;
    border: 1px solid #fff;
  }

  .event__date {
    font-size: 0.625rem;
    margin-left: auto;
  }

  .events-activation {
    margin-top: 0.56rem;
    flex-wrap: wrap;
    gap: 0.8rem;
  }

  .event__btn {
    padding: 0.62rem;
    font-size: 0.875rem;
  }
}
</style>
