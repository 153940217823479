<template>
  <div class="filling-2">
    <img src="@/assets/imgs/video/bg-2-2.png" alt="" class="bg-2" />
    <div class="content-2">
      <p class="title-2">
        Мира, здесь информация о твоих <br />
        суперспособностях
      </p>
      <div class="description-2">
        <div class="description-element-2">
          <div class="skilles__ring pie-small-2" :style="{ '--p': 20 }">
            <p class="skilles__ring-text-2">20%</p>
          </div>
          <div class="description-filling-2">
            <p class="description-title-2">В творческой деятельности</p>
            <p class="description-text-2">
              Однозначно, сделанные на базе интернет-аналитики выводы, которые
              представляют собой яркий пример континентально-европейского типа
              политической культуры, будут ассоциативно распределены по
              отраслям.
            </p>
          </div>
        </div>
        <div class="description-element-2 description-element__right-2">
          <div class="skilles__ring-2 pie-small-2" :style="{ '--p': 20 }">
            <p class="skilles__ring-text-2">20%</p>
          </div>
          <div class="description-filling-2">
            <p class="description-title-2">В творческой деятельности</p>
            <p class="description-text-2">
              Однозначно, сделанные на базе интернет-аналитики выводы, которые
              представляют собой яркий пример континентально-европейского типа
              политической культуры, будут ассоциативно распределены по
              отраслям.
            </p>
          </div>
        </div>
        <div class="description-element-2">
          <div class="skilles__ring-2 pie-small-2" :style="{ '--p': 20 }">
            <p class="skilles__ring-text-2">20%</p>
          </div>
          <div class="description-filling-2">
            <p class="description-title-2">В творческой деятельности</p>
            <p class="description-text-2">
              Однозначно, сделанные на базе интернет-аналитики выводы, которые
              представляют собой яркий пример континентально-европейского типа
              политической культуры, будут ассоциативно распределены по
              отраслям.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentationElement2",
};
</script>

<style scoped>
.filling-2 {
  display: flex;
  align-items: center;
  width: 1920px;
  position: relative;
  height: 1080px;
}

.pie-small-2 {
  --b: 14px;
  --c: #c977ff;
  --w: 150px;
  width: var(--w);
  height: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  place-content: center;
  transform: scale(1, -1);
  flex-shrink: 0;
  z-index: 1;
}

.description-2 {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.description-element-2 {
  display: flex;
  gap: 20px;
  max-width: 60%;
  align-items: center;
}

@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 0;
}

.pie-small-2:before,
.pie-small-2:after {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.pie-small-2:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #7b7995 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}

.skilles__ring-text-2 {
  transform: scale(1, -1);
  font-size: 38.587px;
  line-height: 1;
}

.bg-2 {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.content-2 {
  max-width: 100%;
  width: 100%;
  padding: 100px;
  position: relative;
  height: 100%;
}

.title-2 {
  text-align: center;
  font-size: 55px;
  font-weight: 700;
  line-height: 143.5%;
  letter-spacing: 3.3px;
  text-transform: uppercase;
  text-align: left;
}

.description-title-2 {
  font-size: 33.569px;
  font-weight: 700;
  line-height: normal;
}

.description-text-2 {
  margin-top: 8px;
  font-size: 26.855px;
  font-weight: 500;
  line-height: normal;
}

.description-element__right-2 {
  margin-left: auto;
}

.description-element__right-2 .skilles__ring-2 {
  order: 2;
}

.description-element__right-2 .description-filling-2 {
  text-align: right;
}
</style>
