import {deleteCalendarEvent, fetchCalendarEvents, sendEventClickInfo} from "@/store/calendar/serverInterationMethods";

export const calendar = {
    actions: {
        async fetchCalendarEvents({getters, commit}, {month, months, openEndedEvents}) {
            const {data} = await fetchCalendarEvents(getters.token, month, months, openEndedEvents);
            commit('setCalendarEvents', data);
        },
        async deleteCalendarEvent({getters, commit}, eventId) {
            await deleteCalendarEvent(getters.token, {eventId});
            commit('deleteCalendarEvent', eventId);
        },
        async sendEventClickInfo({getters}, eventId) {
            await sendEventClickInfo(getters.token, {eventId});
        },
    },
    mutations: {
        setCalendarEvents(state, data) {
            state.calendarEvents = data;
        },
        deleteCalendarEvent(state, id) {
            const index = state.calendarEvents.map(item => item.id).indexOf(id);
            if (index !== -1) {
                state.calendarEvents.splice(index, 1);
            }
        }
    },
    state: {
        calendarEvents: []
    },
    getters: {
        calendarEvents: state => state.calendarEvents
    }
}
