import {makeCorsPostReq, makeGetReq, makePostReq} from "../helpers/request";
import {defaultHttpResHandler} from "../helpers/defaultHttpResHandler";
import {appConfig} from "@/lib/appConfig";


// http request for login into system
export const submitAuthorization = async (data, role) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(`${appConfig.domain}/${role}/login`, data, {data: true});
    });
};


export const fetchVkToken = async (token, data) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(`${appConfig.domain}/student/login/vk`, data, {data: true, token});
    });
};

// http request for default register process
export const submitRegistration = async (data, role) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(`${appConfig.domain}/${role}/register`, data, {data: true});
    });
};

export const checkUserEmail = async (data) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(`${appConfig.domain}/student/unique`, data, {data: true});
    });
};


export const fetchUserData = async (token) => {
    return await defaultHttpResHandler(async () => {
        return await makeGetReq(`${appConfig.domain}/student`, {token});
    });
};


export const fetchPostPreview = async (token) => {
    return await defaultHttpResHandler(async () => {
        return await makeGetReq(`${appConfig.domain}/student/post/preview`, {token});
    });
};

export const createPostVk = async (token, data) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(`${appConfig.domain}/student/vk/post`, data, {token, data: true});
    });
};

export const downloadVideo = async (token) => {
    return await defaultHttpResHandler(async () => {
        return await makeGetReq(`http://37.9.4.153:8001/video`, {token});
    });
};
export const downloadCertificate = async (token) => {
    return await defaultHttpResHandler(async () => {
        return await makeGetReq(`${appConfig.domain}/student/results`, {token, blob: true});
    });
};
export const downloadPresentation = async (token) => {
    return await defaultHttpResHandler(async () => {
        return await makeGetReq(`${appConfig.domain}/student/parents`, {token, blob: true});
    });
};

export const fetchStudentStatistic = async (token) => {
    return await defaultHttpResHandler(async () => {
        return await makeGetReq(`${appConfig.domain}/student/missions`, {token});
    });
};

export const makePay = async (token) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(`${appConfig.domain}/student/payment_alpha`, null, {token});
    });
};

export const submitRegistrationPayment = async (token, data) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(`${appConfig.domain}/student/payment_registration`, data, {token, data: true});
    }, {isPayment: true});
};

export const fetchPaymentStatus = async (token) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(`${appConfig.domain}/student/payment_alpha/accept`, null, {token});
    });
};

export const sendEmailPost = async (token, data) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(`${appConfig.domain}/student/email`, data, {token, data: true});
    });
};

export const fetchStudentStatisticForVideo = async (userId) => {
    return await defaultHttpResHandler(async () => {
        return await makeGetReq(`${appConfig.domain}/student/missions/public?id=${userId}`);
    });
};

export const fetchUserAnswers = async (token) => {
    return await defaultHttpResHandler(async () => {
        return await makeGetReq(`${appConfig.domain}/student/chat`, {token});
    });
};

export const saveAnswers = async (token, answers) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(`${appConfig.domain}/student/chat`, answers, {token, data: true});
    });
};

export const saveUserData = async (token, data, role) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(`${appConfig.domain}/${role}`, data, {token, data: true});
    });
};


export const fetchRegions = async () => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/regions`, {}));


export const fetchCities = async (data) => await defaultHttpResHandler(async () => await makeCorsPostReq('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {
    data, headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": "Token 0d1c626624b5430628ce3116496ddb478ebb6269"
    }
}));




