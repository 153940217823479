<template>
  <div class="certificate">
    <div class="filling">
      <img src="@/assets/imgs/video/cert.png" alt="" class="title" />

      <p class="subtitle">ПОДТВЕРЖДАЕТ, ЧТО</p>
      <p class="text text__first">Этот сертификат вручается</p>
      <img src="@/assets/imgs/video/test-photo.png" alt="" class="photo" />
      <p class="name">Мира Жестова</p>
      <p class="info">
        Ученица СГТУ имени Гагарина Ю.А г. Cаратов, Cаратовская область
      </p>
      <p class="text text__last">
        я воспользовался(-лась) сервисом <br />
        “ЦИФРОВОЙ ПОМОЩНИК <br />
        ДЛЯ ВЫБОРА ПРОФЕССИИ”
      </p>
      <div class="sponsors">
        <img src="@/assets/imgs/companies/profstor.png" alt="" class="sponsors-element profstor" />
        <img src="@/assets/imgs/companies/za.png" alt="" class="sponsors-element za" />
        <img src="@/assets/imgs/video/min.png" alt="" class="sponsors-element min" />
      </div>
      <div class="signature">
        <div class="signature-filling">
          <img src="@/assets/imgs/video/signature.png" alt="" class="signature-img" />
        </div>
        <p class="signature-author">Автор проекта- Алиева Н.X</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CertificatePage",
};
</script>

<style scoped>
.certificate {
  background: url("@/assets/imgs/certificate.png");
  background-repeat: no-repeat;
  width: 1993px;
  height: 1395px;
  background-size: contain;
}

.title {
  margin-top: 180px;
  width: 785px;
}

.subtitle {
  margin-top: 29px;
  color: #a6aeff;
  font-size: 40.469px;
  font-weight: 700;
  line-height: 48.563px;
  letter-spacing: 2.023px;
}

.text {
  color: #fff;
  font-size: 32.375px;
  line-height: normal;
}

.text__first {
  margin-top: 29px;
}

.photo {
  margin-top: 32px;
  width: 95px;
  height: 95px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.name {
  font-family: "BRUSHSCI";
  font-size: 106px;
  font-style: italic;
  font-weight: 400;
  line-height: 129.502px;
  border-bottom: 4px dashed #fff;
}

.info {
  margin: 58px auto 0 auto;
  color: #949eff;
  font-size: 40.469px;
  max-width: 800px;
  font-weight: 400;
  line-height: normal;
}

.text__last {
  margin-top: 16px;
  color: #fff;
  font-size: 32.375px;
  font-weight: 400;
  line-height: 40.469px;
}

.sponsors {
  margin-top: 32px;
  display: grid;
  align-items: center;
  gap: 80px;
  justify-content: center;
  grid-template-columns: repeat(3, auto);
}

.profstor {
  width: 56px;
}

.za {
  width: 180px;
}

.min {
  width: 52px;
}

.work {
  width: 101px;
}

.signature {
  margin: 18px auto 0 auto;
  width: 167px;

  justify-content: center;
  flex-direction: column;
}

.signature-filling {
  border-bottom: 4px dashed #fff;
}

.signature-img {
  margin-bottom: -8px;
  width: 66px;
}

.signature-author {
  font-size: 13px;
  margin-top: 12px;
}

.filling {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}
</style>
