<template>
  <div class="filling" :class="{ 'step': step === 12 }">
    <img src="@/assets/imgs/video/bg-13.webp" alt="" class="bg" />
    <img src="@/assets/imgs/video/decoration-13-1.webp" alt="" class="decoration-1 decoration"
      :class="{ 'animate-1 ': step === 12 }" />
    <div class="description">
      <div class="description-element description-element-first">
        <img src="@/assets/imgs/video/decoration-13-2.webp" alt="" class="decoration-2 decoration"
          :class="{ 'animate-1 ': step === 12 }" />
        <p class="text text__1" :class="{ 'animate-2': viewAnimate }">
          {{ motivations?.[0] }}
        </p>
      </div>
      <div class="description-element">
        <img src="@/assets/imgs/video/decoration-13-2.webp" alt="" class="decoration-3 decoration"
          :class="{ 'animate-1 ': step === 12 }" />
        <p class="text text__2" :class="{ 'animate-2': viewAnimate }">
          {{ motivations?.[1] }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  name: "VideoElement13",
  props: ["motivations", "step"],
  setup() {
    const viewAnimate = ref(false);

    onMounted(() => setTimeout(() => (viewAnimate.value = true), 1000));
    return {
      viewAnimate,
    };
  },
};
</script>

<style scoped>
.filling {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.decoration {
  position: absolute;
}

.decoration-1 {
  height: 78.28vw;
  left: -5.68vw;
  top: -9.69vw;
}

.decoration-2 {
  width: 22.24vw;
  height: 8.8vw;
  position: relative;
}

.description {
  display: flex;
  flex-direction: column;

}

.description-element-first {
  margin-bottom: 4vw;
}

.description-element {
  display: flex;
  align-items: center;
}

.decoration-3 {
  width: 22.24vw;
  height: 8.8vw;
  position: relative;
}

.text {
  font-size: 2.5vw;
  margin-left: 1vw;
  font-weight: 500;
  line-height: 106.99%;
  position: relative;
  z-index: 2;
  width: 42vw;
  opacity: 0;
  transition: all 1s ease-in-out;
}

.animate-1 {
  animation: content-open 1s 0.5s both;
  will-change: transform;
}

.animate-2 {
  opacity: 1;
}

@keyframes content-open {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
