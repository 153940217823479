<template>
  <div class="filling" :class="{ 'step': step === 3 }">
    <img src="@/assets/imgs/video/bg-4.webp" alt="" class="bg" />
    <div class="content">
      <div class="images " :class="{ 'image-animation': step === 3 }">
        <div class="image image-1">
          <img src="@/assets/imgs/video/photos/abroad.webp" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('За рубежом')" />
          <img src="@/assets/imgs/video/photos/rus.webp" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('В России')" />
        </div>
        <div class="image image-1">
          <img src="@/assets/imgs/video/photos/gori.jpg" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('В горах')" />
          <img src="@/assets/imgs/video/photos/more.jpg" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('На море')" />
        </div>
         <div class="image image-1">
          <img src="@/assets/imgs/video/photos/udalenka.jpeg" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('Из дома')" />
          <img src="@/assets/imgs/video/photos/office.jpg" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('В офисе')" />
        </div>
        <div class="image image-2">
          <img src="@/assets/imgs/video/photos/home.webp" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('Дом')" />
          <img src="@/assets/imgs/video/photos/flat.webp" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('Квартира')" />
        </div>
        <div class="image image-3">
          <img src="@/assets/imgs/video/photos/car.webp" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('Автомобиль')" />
          <img src="@/assets/imgs/video/photos/bike.webp" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('Мотоцикл')" />
        </div>
        <div class="image image-4">
          <img src="@/assets/imgs/video/photos/pet.webp" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('Животные')" />
          <img src="@/assets/imgs/video/photos/flower.webp" alt="" class="image-filling"
            v-if="statistic?.yourFuturePicNames?.includes('Растения')" />
        </div>
      </div>
      <div class="description">
        <p class="text " :class="{ 'text-animation-1': step === 3 }">Всё, что</p>
        <p class="text text__big " :class="{ 'text-animation-2': step === 3 }">ты хочешь</p>
        <p class="text " :class="{ 'text-animation-3': step === 3 }">В будущем</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoElement4",
  props: ["statistic", 'step'],
};
</script>

<style scoped>
.filling {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.image {
  position: absolute;
}

.image-filling {
  width: 17.92vw;
  height: 20.63vw;
  transform: rotate(30deg);
}

.content {
  display: flex;
  align-items: center;
  padding-left: 3.7vw;
  position: absolute;

  max-width: 100%;
  width: 100%;
}

.images {
  width: 50.94vw;
  height: 39.69vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  margin-left: 14.74vw;
  display: flex;
  flex-direction: column;
}

.image-1 {
  left: 0;
}

.image-2 {
  top: 0;
}

.image-3 {
  right: 0;
}

.image-4 {
  bottom: 0;
}

.text {
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  text-transform: uppercase;
}

.text__big {
  font-size: 3.33vw;
  margin: 0.83vw 0;
}

.image-animation {
  animation: image-open 2s both;
  will-change: transform;
}

.text-animation-1 {
  animation: text-open-1 1s both;
  will-change: transform;
}

.text-animation-2 {
  animation: text-open-1 1.5s both;
  will-change: transform;
}

.text-animation-3 {
  animation: text-open-1 2s both;
  will-change: transform;
}

@keyframes image-open {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes text-open-1 {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes text-open-2 {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes text-open-3 {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
