<template>
  <div class="filling" :class="{ 'step': step === 14 }">
    <img src="@/assets/imgs/video/bg-15.webp" alt="" class="bg" />

    <p class="title">Трудоустройства</p>
    <div class="company">
      <div class="company-element" :class="{ 'animate-2': step === 14 }" v-for="item in companies" :key="item.id">
        <p class="company-text">{{ item.name }}</p>
        <img :src="item.preview" alt="" class="company-img company-img__1" />
      </div>
      <!--      <div class="company-element">-->
      <!--        <p class="company-text">Lucoil</p>-->
      <!--        <img src="@/assets/imgs/video/company-2.svg" alt="" class="company-img company-img__2">-->
      <!--      </div>-->
      <!--      <div class="company-element">-->
      <!--        <p class="company-text">Lucoil</p>-->
      <!--        <img src="@/assets/imgs/video/company-3.svg" alt="" class="company-img company-img__3">-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoElement15",
  props: ["companies", "step"],
};
</script>

<style scoped>
.filling {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.title {
  margin-top: 5.21vw;
  text-align: center;
  color: #fff;
  text-align: center;
  font-size: 3.75vw;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  position: relative;
  z-index: 2;
}

.bg {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.company {
  position: relative;
  z-index: 2;
  margin-top: 7.55vw;
  display: flex;
  justify-content: center;
}

.company :last-child {
  margin-right: 0;
}

.company-img {
  width: 9.69vw;
  height: 9.69vw;
}

.company-element {
  padding: 3.02vw 0 0 0;
  width: 24.38vw;
  height: 27.66vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg,
      rgba(255, 255, 255, 0.24) 0%,
      rgba(255, 255, 255, 0.06) 100%);
  backdrop-filter: blur(50px);
  position: relative;
  overflow: hidden;
  margin-right: 4.21vw;
}



.company-text {
  color: #fff;
  text-align: center;
  font-size: 2.08vw;

  font-weight: 500;
  line-height: 106.99%;
}

.company-img {
  height: auto;
  max-height: 60%;
  width: 100%;
  margin-top: auto;
}

.animate-2 {
  animation: content-open-2 1.5s 0.5s both;
  will-change: transform;
}

@keyframes content-open-2 {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.company-img__2 {
  width: 19.27vw;
  height: 24.53vw;
  left: 0.63vw;
  bottom: -12.24vw;
}

.company-img__3 {
  width: 21.67vw;
  height: 25.52vw;
  left: -0.26vw;
  bottom: -9.22vw;
}
</style>
