<template>
  <div class="filling-3">
    <img src="@/assets/imgs/video/bg-4.webp" alt="" class="bg-3" />
    <div class="content-3">
      <div class="images-3">
        <div class="image-3 image-1-3">
          <img
            src="@/assets/imgs/video/photos/abroad.webp"
            alt=""
            class="image-filling-3"
            v-if="statistic?.yourFuturePicNames?.includes('За рубежом')"
          />
          <img
            src="@/assets/imgs/video/photos/rus.webp"
            alt=""
            class="image-filling-3"
            v-if="statistic?.yourFuturePicNames?.includes('В России')"
          />
        </div>
        <div class="image-3 image-1-3">
          <img
              src="@/assets/imgs/video/photos/gori.jpg"
              alt=""
              class="image-filling-3"
              v-if="statistic?.yourFuturePicNames?.includes('В горах')"
          />
          <img
              src="@/assets/imgs/video/photos/more.jpg"
              alt=""
              class="image-filling-3"
              v-if="statistic?.yourFuturePicNames?.includes('На море')"
          />
        </div>
        <div class="image-3 image-2-3">
          <img
            src="@/assets/imgs/video/photos/home.webp"
            alt=""
            class="image-filling-3"
            v-if="statistic?.yourFuturePicNames?.includes('Дом')"
          />
          <img
            src="@/assets/imgs/video/photos/flat.webp"
            alt=""
            class="image-filling-3"
            v-if="statistic?.yourFuturePicNames?.includes('Квартира')"
          />
        </div>
        <div class="image image-3-3">
          <img
            src="@/assets/imgs/video/photos/car.webp"
            alt=""
            class="image-filling-3"
            v-if="statistic?.yourFuturePicNames?.includes('Автомобиль')"
          />
          <img
            src="@/assets/imgs/video/photos/bike.webp"
            alt=""
            class="image-filling-3"
            v-if="statistic?.yourFuturePicNames?.includes('Мотоцикл')"
          />
        </div>
        <div class="image-3 image-4-3">
          <img
            src="@/assets/imgs/video/photos/pet.webp"
            alt=""
            class="image-filling-3"
            v-if="statistic?.yourFuturePicNames?.includes('Животные')"
          />
          <img
            src="@/assets/imgs/video/photos/flower.webp"
            alt=""
            class="image-filling-3"
            v-if="statistic?.yourFuturePicNames?.includes('Растения')"
          />
        </div>
       <div class="image-3 image-4-3">
          <img
            src="@/assets/imgs/video/photos/udalenka.jpeg"
            alt=""
            class="image-filling-3"
            v-if="statistic?.yourFuturePicNames?.includes('Из дома')"
          />
          <img
            src="@/assets/imgs/video/photos/office.jpg"
            alt=""
            class="image-filling-3"
            v-if="statistic?.yourFuturePicNames?.includes('В офисе')"
          />
        </div>
      </div>
      <div class="description-3">
        <p class="text-3">Мира, таким</p>
        <p class="text-3 text__big-3">может быть</p>
        <p class="text-3">твоё будущее</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PresentationElement3",
  props: ["statistic", "step"],
};
</script>

<style scoped>
.filling-3 {
  display: flex;
  align-items: center;
  width: 1920px;
  justify-content: center;
  position: relative;
  height: 1080px;
}

.bg-3 {
  position: absolute;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.image-3 {
  position: absolute;
}

.image-filling-3 {
  width: 344px;
  height: 396px;
  transform: rotate(30deg);
}

.content-3 {
  display: flex;
  align-items: center;
  padding-left: 71px;
  gap: 160px;
  position: absolute;
  max-width: 100%;
  width: 100%;
}

.images-3 {
  width: 978px;
  height: 762px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description-3 {
  display: flex;
  max-width: 633px;
  flex-direction: column;
}

.image-1-3 {
  left: 0;
}

.image-2-3 {
  top: 0;
}

.image-3-3 {
  right: 0;
}

.image-4-3 {
  bottom: 0;
}

.text-3 {
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 3.6px;
  text-transform: uppercase;
}

.text__big-3 {
  font-size: 64px;
}
</style>
